import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { DashboardService } from './services/dashboard.service';
import { PreloginService } from './services/prelogin.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'; // import Router and NavigationEnd
import { WindowRefService } from './services/window-ref.service';
import { Title } from '@angular/platform-browser';
import { map, filter, switchMap } from 'rxjs/operators';

// declare gtag as a function to set and sent the events
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DashboardService]
})
export class AppComponent implements OnInit, AfterViewInit {

  isLoggedIn;

  constructor(private title: Title, public router: Router, private activatedRoute: ActivatedRoute, private renderer: Renderer2, private preLoginService: PreloginService, private windowRef: WindowRefService) {
    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-70477724-1',
          {
            page_location: 'https://www.mindler.com/partner' + event.urlAfterRedirects
          }
        );
        // 'page_location' is optional, but mandatory in our case.
        // Reason - A normal app land's at www.xyz.com and it is taken as the hostname and 'dl' param in trigger to gtag
        // but our app lands at https://www.mindler.com/partner/login or /partner-registration or whatever, and it is set for
        // entire traversing until the page is refreshed, and on refresh the current page will be set to hostname which is also wrong so,
        // we explicitly set it to 'https://www.mindler.com/partner' in any case.
        // google analytics either reads dl or dh+dp, also dl=dh+dp


        // HubSpot
        const _hsq = windowRef.nativeWindow._hsq = windowRef.nativeWindow._hsq || [];
        _hsq.push(['setPath', '/partner' + event.urlAfterRedirects]);
        _hsq.push(['trackPageView']);
      }
    });


    // Page Title
    this.router.events.pipe(filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap(route => route.data),
      map((data) => {
        return data && data.title ? `${data.title}` : 'Mindler Partner';
      })
    ).subscribe((pageTitle) => this.title.setTitle(pageTitle));

  }

  // Application Loader START
  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#loader');
    loader.style.display = 'none';
  }
  // Application Loader END

  ngOnInit() {
    this.preLoginService.getLoggedInFlagForNav().subscribe(data => {
      this.isLoggedIn = data;
    });

    if (localStorage.getItem('pp_session') != null) {
      this.isLoggedIn = true;
    }
    else {
      this.isLoggedIn = false;
    }
  }
}
