import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreloginService } from '../../services/prelogin.service';
import { PasswordValidator } from '../../custom_validators/password.validator';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router, private preLoginService: PreloginService) { }

  resetPasswordForm: FormGroup;

  isResetPasswordFormSubmitted = false;

  isFieldTypeText = false;

  urlParamQuery;
  isPasswordResetSuccess;
  isPasswordResetValid = true;

  async ngOnInit() {
    await this.activatedRoute.queryParams.subscribe(async params => {
      this.urlParamQuery = params.query;
      var data = {token: this.urlParamQuery};
      await this.preLoginService.isResetPasswordValid(data).subscribe(async response => {
        if(response.statusCode == 200 && !response.status){
          this.isPasswordResetValid = false;
        }
      })
    });

    // redirect already logged in user to dashboard
    if (localStorage.getItem('pp_session') != null) {
      this.router.navigate(['/my-dashboard']);
    }

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, { validator: PasswordValidator });

  }

  // getters
  get email() { return this.resetPasswordForm.get('email'); }
  get password() { return this.resetPasswordForm.get('password'); }
  get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }


  togglePasswordFieldType() {
    this.isFieldTypeText = !this.isFieldTypeText;
  }

  sendForgotPasswordCall() {
    this.router.navigate(['/forgot-password']);
  }

  // Reset Password Form submit
  resetPasswordFormSubmit() {
    this.isResetPasswordFormSubmitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }
    const resetPasswordPostData = {
      key: this.urlParamQuery,
      password: this.password.value
    };

    this.preLoginService.resetPassword(resetPasswordPostData).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.isPasswordResetSuccess = true;
        this.resetPasswordForm.reset();
        this.isResetPasswordFormSubmitted = false;
      }
      else if (response.statusCode == 200 && response.status == false) {
        this.isPasswordResetValid = false;
      }
      // else {
      //   this.isPasswordReset = false;
      // }
    });
  }

}
