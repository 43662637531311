import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreloginService } from '../../services/prelogin.service';
import { DashboardService } from '../../services/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';

import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private preLoginService: PreloginService, private dashboardService: DashboardService) { }

  errorCode;
  message;
  isError;
  loginBtnLabel = 'Log In';

  // flag is set to true on button click, and is does not indicates that form has been submitted
  isLoginFormSubmitted = false;

  // Forms
  loginForm: FormGroup;

  isInvalidPassword = false;
  userNotExists = false;
  isErroredOut = false;
  isSubscriptionExpired = false;
  fieldTextType: boolean = false;

  ngOnInit() {
    // redirect already logged in user to dashboard
    if (localStorage.getItem('pp_session') != null) {
      this.router.navigate(['/my-dashboard']);
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.hasOwnProperty('err')) {
        this.errorCode = params.err;
        if (this.errorCode == 403) {
          this.isError = true;
          this.message = 'Session Expired!';
        }
        else if (this.errorCode == 0) {
          this.isError = true;
          this.message = 'Unknown Error!';
        }
      }
    });

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // getters
  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  // Login Form submit
  loginFormSubmit() {
    this.resetFlags();

    this.isLoginFormSubmitted = true;

    if (this.loginForm.invalid) {
      return;

    }
    this.loginBtnLabel = 'Logging In...';

    this.preLoginService.login(this.loginForm.value).subscribe(data => {
      this.loginBtnLabel = 'Log In';
      if (data.statusCode == 200 && data.status == false) {
        this.isInvalidPassword = data.message;  // also handles User Inactive
      }
      else if (data.statusCode == 404 && data.status == false) {
        this.userNotExists = data.message;
      }
      else if (data.statusCode == 500 && data.status == false) {
        this.isErroredOut = data.message;
      }
      else if (data.statusCode == 200 && data.status == true) {
        const decodedToken: any = jwt_decode(data.token);
        if (decodedToken.counselor_id != null && decodedToken.subscription_status == 0 && decodedToken.trial_period == 0) {
          this.isSubscriptionExpired = true;
        }
        else {
          this.router.navigate(['/my-dashboard']);
        }
      }
    },
      error => {
        this.loginBtnLabel = 'Log In';
      });
  }

  resetFlags() {
    this.isInvalidPassword = false;
    this.userNotExists = false;
    this.isSubscriptionExpired = false;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  selectedBorder() {
    var eyeIcon = document.getElementById('eye-icon');
    eyeIcon.style.borderBottom = "2px solid #007fb6";
  }

  unselectedBorder() {
    var eyeIcon = document.getElementById('eye-icon');
    eyeIcon.style.borderBottom = "2px #e9e9e9 solid";
  }
}
