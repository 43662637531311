import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { DashboardService } from '../../services/dashboard.service';
import { WindowRefService } from '../../services/window-ref.service';
import { environment } from '../../../environments/environment';
import * as workerTimers from 'worker-timers';
// import { DomSanitizer } from '@angular/platform-browser';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
enum ZOHO_SUBSCRIPTION_STATUS {
  INITIATED = 1,
  PROCESSING,
  COMPLETED
}
declare var $: any;
@Component({
  selector: 'app-billing-invoice',
  templateUrl: './billing-invoice.component.html',
  styleUrls: ['./billing-invoice.component.scss']
})
export class BillingInvoiceComponent implements OnInit {

  isLoading = true;
  apiResponse;
  activePlanName;

  // Additional Coupons and Buy More
  couponDetailsAndPriceResponse;
  isBuyMoreButtonClicked = false;
  isBuyMoreVipButtonClicked = false;
  // Edit Billing Info
  isEditBillingInformation = false;

  // Update Plan
  upgradeActionButtonClicked = false;
  planDataToSend;
  modalRefToSend;
  flagToTriggerChangeEvent = 0;

  // Billing Information Form
  billingInformationForm: FormGroup;
  isBillingInformationFormSubmitted: boolean;
  billingFormSaveBtnLabel = 'Save Changes';

  // Send On Email
  sendOnEmailBtnRef;

  //
  noOfPages;
  offsetValue;
  isManualPlanLockMessage: string = "You have enrolled for manual subscription plan.<br>Please contact our Mindler representative for billing information.<br> Or just write to us at partner@mindler.com";
  showPaymentConfirm = false;
  paymentMsgInfo: string;
  popupWindow: any
  clickedPlanData: any;
  SubscriptionInitiated: boolean = false;
  zohoSubscriptionStatus: number = 1;

  typeTwoPlanBtnDisabled: boolean = false;
  constructor(private zone: NgZone,
    private windowRef: WindowRefService,
    private formBuilder: FormBuilder,
    private validationService: ValidationsService,
    private dashboardService: DashboardService,
    private router: Router) {
    // subscribe to subscription change request flag
    this.dashboardService.getPlanChangeRequestFlag().subscribe(data => {
      this.apiResponse.plan_change_or_cancellation_request = data;
    });

    // subscribe to Coupons Bought Success flag
    this.dashboardService.getCouponBoughtFlag().subscribe(data => {
      this.zone.run(() => {
        $('#buyMoreCoupons').modal('hide');
        this.getBillingInfoPageDetails();
      });
    });
  }

  ngOnInit() {

    this.billingInformationForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, this.validationService.validateEmail]],
      gst: ['']
    });

    this.getBillingInfoPageDetails();
    this.dashboardService.planBtnDisabledStatus.subscribe((btnDisabled) => {
      this.typeTwoPlanBtnDisabled = btnDisabled;
    })
  }

  // getters - billingInformationForm
  get name() { return this.billingInformationForm.get('name'); }
  get phone() { return this.billingInformationForm.get('phone'); }
  get email() { return this.billingInformationForm.get('email'); }
  get gst() { return this.billingInformationForm.get('gst'); }

 

  //
  getBillingInfoPageDetails() {
    this.dashboardService.getBillingInfoPageDetails().subscribe(response => {
      this.isLoading = false;
      if (response.statusCode == 200 && response.status == true) {
        if (response.data) {
          this.apiResponse = response.data;
          this.noOfPages = response.data.bills_count;

          (this.apiResponse.plan).forEach(element => {
            element.amount = Math.floor(element.amount);
            if (element.activePlan == true) {
              this.zone.run(() => {
                this.activePlanName = element.plan;
              });
            }
          });
        }
      } else if (response.statusCode == 200 && response.status == false) {
        this.router.navigate(['/upgrade'])
      }
    });
  }


  // Upgrade, Downgrade, Cancel Renewal (Shared component - plan-action-modals)
  billingPlanAction(data, modalRef) {
    this.dashboardService.managePlanBtnDisabled(true);
    if (this.apiResponse.plan_change_or_cancellation_request) {
      return;
    }
    if (data.action == 'Downgrade' || data.action == 'Cancel Renewal') {
      this.upgradeActionButtonClicked = true;
      this.flagToTriggerChangeEvent = this.flagToTriggerChangeEvent + 1;
      this.planDataToSend = data;
      this.modalRefToSend = modalRef;
    } else if (data.action == 'Upgrade') {
      this.upgradePlans(data);
    }
  }

  //not in use
  /* updatePaymentDetailsFormSubmit() {
    this.dashboardService.getSubscriptionId().subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        const options = {
          key: this.dashboardService.getRazorpayKey(),
          subscription_id: response.data.subscription_id,
          subscription_card_change: 1,
          name: 'Mindler',
          description: response.data.description,
          image: 'https://mindlerpartner.imgix.net/partner-assets/m-blue-logo.png',
          prefill: { name: response.data.name, email: response.data.email },
          theme: { color: '#007fb6' },
          handler: ((razorpayResponse) => {
            const updateCardDetailsPostData = {
              razorpay_payment_id: razorpayResponse.razorpay_payment_id
            };
            this.dashboardService.updateCardDetails(updateCardDetailsPostData).subscribe(response => {
              if (response.statusCode == 200 && response.status == true) {
                this.zone.run(() => {
                  this.apiResponse.last_four = response.data.last_four;
                  this.apiResponse.network = response.data.network;
                });
              }
            });
          })

        };
        const rzp1 = new this.windowRef.nativeWindow.Razorpay(options);
        rzp1.open();
      }
    });
  } */

  // Buy More Coupons
  buyMoreCouponsAction() {
    this.dashboardService.getPartnerCouponDetails().subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.couponDetailsAndPriceResponse = response.data;
      }
    });
    this.isBuyMoreButtonClicked = true;
  }

  buyMoreVipAction() {
    this.isBuyMoreVipButtonClicked = true;
  }
  closex(){
    this.isBuyMoreVipButtonClicked = false;
  }
  buyNowSubmit(event) {
    this.isBuyMoreVipButtonClicked = event
  }
  closeCoupon(){
    this.isBuyMoreButtonClicked = false;
  }
  buyNowCouponSubmit(event) {
    this.isBuyMoreButtonClicked = event
  }
  

  //  Billing Information
  setBillingInformationVisibility() {
    this.isEditBillingInformation = !this.isEditBillingInformation;

    this.billingInformationForm.patchValue({
      name: this.apiResponse.billing_details.name,
      phone: this.apiResponse.billing_details.phone,
      email: this.apiResponse.billing_details.email,
      gst: this.apiResponse.billing_details.gst
    });
  }

  billingInformationFormSubmit() {
    this.isBillingInformationFormSubmitted = true;

    if (this.billingInformationForm.invalid) {
      return;
    } 
    this.billingFormSaveBtnLabel = 'Saving...';

    this.dashboardService.updateBillingInformationDetails(this.billingInformationForm.value).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.billingFormSaveBtnLabel = 'Save Changes';

        this.apiResponse.billing_details.name = response.data.billing_details[0].name;
        this.apiResponse.billing_details.phone = response.data.billing_details[0].phone;
        this.apiResponse.billing_details.email = response.data.billing_details[0].email;
        this.apiResponse.billing_details.gst = response.data.billing_details[0].gst;

        this.isEditBillingInformation = !this.isEditBillingInformation;
      }
    });
  }

  //  Send Invoice on Email
  sendBillOnEmail(billId, btnRef) {
    this.sendOnEmailBtnRef = btnRef;

    this.sendOnEmailBtnRef.target.innerText = 'Sending...';

    this.dashboardService.sendBillOnEmail({ id: billId }).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.sendOnEmailBtnRef.target.innerText = 'Sent';

        this.sendOnEmailBtnText();
      }
      else if (response.status == false) {
        this.sendOnEmailBtnRef.target.innerText = 'Save Changes';
      }
    });
  }

  sendOnEmailBtnText() {
    setTimeout(() => {
      this.sendOnEmailBtnRef.target.innerText = 'Send on Email';
    }, 5000);
  }

  paginate(event) {
    this.offsetValue = (event.first);

    this.dashboardService.getBills({ offset: this.offsetValue }).subscribe(response => {
      if (response.statusCode == 200 && response.status == true) {
        this.apiResponse.bills = response.data.bills;
        this.noOfPages = response.data.bills_count;
      }
      else if (response.status == false) {
      }
    });
  }

  // Telephone Input
  getNumber(fullNumber) {
    this.billingInformationForm.patchValue({
      phone: fullNumber
    });
  }

  hasError(event: any): void {
    if (!event && this.phone.value !== '') {
      this.phone.setErrors({ invalidPhoneNumber: true });
    }
  }

  upgradePlans(data) {
    let PLAN_TYPE = {
      1: 'Agency Plan',
      2: 'Professional Plan',
      3: 'Starter Plan'
    }
    this.paymentMsgInfo = `By clicking on the continue button, you will get redirected to the payment page for the ${PLAN_TYPE[data.plan_type]} where you can make the payment.`
    this.clickedPlanData = data
    this.showPaymentConfirm = true;
    $("#planActionModalUpgrade").modal('show')
  }

  onPaymentConfirmation(planData) {
    this.upgradationProcessingPlan(planData);
  }

  upgradationProcessingPlan(planData) {
    // As for now, no annual subscription
    const createSubscriptionPostData = {
      plan: planData.plan_type,
      type: planData.recurring_type,
      status: planData.recurring_type == null ? 'trial' : null,
      subscription_status: planData.recurring_type == null ? null : 0
    }
    this.SubscriptionInitiated = true;
    this.zohoSubscriptionStatus = ZOHO_SUBSCRIPTION_STATUS.INITIATED
    this.dashboardService.initiateSubscription(createSubscriptionPostData).subscribe(response => {
      this.isLoading = false;
      if (response.statusCode == 200 && response.status == true) {
        if (response.data && response.data.invoice_url) {
          // this.invoiceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(response.data.invoice_url + '&origin=' + 'https://zohosecurepay.com/')
          var left = ($(window).width() / 8), top = ($(window).height() / 8);
          this.popupWindow = window.open(response.data.invoice_url, '_blank')// "Mindler Subscription", `width=${$(window).width() - $(window).width() / 5}, height=${$(window).height() - $(window).height() / 4}, top=${top}, left=${left},toolbar=0,location=0, directories=0, status=0, menubar=0'`);;
          this.monitoringZohoSubscription();
        }
      }
    });
  }


  closePlanActionModalUpgrage() {
    $("#planActionModalUpgrade").modal('hide');
    this.showPaymentConfirm = false;
    this.dashboardService.managePlanBtnDisabled(false);
  }
  intervalId: any;
  monitoringZohoSubscription() {
    this.zohoSubscriptionStatus = ZOHO_SUBSCRIPTION_STATUS.PROCESSING;
    this.intervalId = workerTimers.setInterval(() => {
      // do something many times
      this.gettingZohoPayementStatus();
      if (ZOHO_SUBSCRIPTION_STATUS.COMPLETED == this.zohoSubscriptionStatus) {
        workerTimers.clearInterval(this.intervalId);
      }
    }, 3000);
  }

  gettingZohoPayementStatus() {
    if (this.zohoSubscriptionStatus == ZOHO_SUBSCRIPTION_STATUS.PROCESSING) {
      this.dashboardService.refreshToken().subscribe(response => {
        if (response.statusCode == 200 && response.status == true) {
          const decodedToken: any = (jwt_decode(response.token))
          const subscriptionStatus = decodedToken.subscription_status;
          console.log(subscriptionStatus)
          //updatingToken
          if (this.clickedPlanData.plan == decodedToken.active_plan) {
            localStorage.setItem('pp_token', response.token);
            if (subscriptionStatus) {
              this.dashboardService.setFirstTimePlanPurchaseFlag();
              this.zohoSubscriptionStatus = ZOHO_SUBSCRIPTION_STATUS.COMPLETED;
              let updateSubscriptionData = {
                status: decodedToken.active_plan,
                subscription_status: subscriptionStatus
              }
              this.dashboardService.updateSubscription(updateSubscriptionData).subscribe(response => { })
              this.SubscriptionInitiated = false
              this.getBillingInfoPageDetails();
              this.popupWindow.close();
              this.dashboardService.managePlanBtnDisabled(false);
            }
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.intervalId) {
      workerTimers.clearInterval(this.intervalId);
      this.popupWindow.close();
    }
  }

  viewBill(bill) {
    this.popupWindow = window.open(bill.invoice_url, '_blank')
  }

  get activePlanData() {
    let plans = this.apiResponse.plan.filter((plan) => plan.activePlan === true) || [];
    return plans;
  }

  get isManualPlan() {
    return (this.apiResponse && (this.apiResponse.type == 'quarter' || this.apiResponse.type == 'annual')) ? false : true
  }

}
