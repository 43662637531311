// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`. - NOT IN OUR CASE
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,

	logo_baseUrl: 'https://mindlerpartner.imgix.net/partner-logo/',
	featured_photo_baseUrl: 'https://mindlerpartner.imgix.net/partner-featured-photos/',
	counsellor_photo_baseUrl: 'https://mindlerpartner.imgix.net/counselor-logo/',
	partner_photo_baseUrl: 'https://mindlerpartner.imgix.net/partner-profile-image/',
	assests_baseUrl: 'https://mindlerpartner.imgix.net/partner-assets/',
	student_report_baseUrl: 'https://student-info.s3-us-west-2.amazonaws.com/',
	student_photo_baseUrl: 'https://mindlerstudentinfo.imgix.net/',
	action_plan_resource_baseUrl: 'https://mindler-partner.s3.ap-south-1.amazonaws.com/student-action-plan/',
	
	local: {
		baseUrl: 'https://developmentapis.mindler.com/partner/api/v1/',
		MINDLER_PROFILE_BUILDER_API_URL: `https://api.mindler.com/api/profileBuilder/v1`,
		roadmapBaseUrl: 'https://developmentapis.mindler.com/partner/roadmap/v1/',
		abroadRoadmapBaseUrl: 'https://developmentapis.mindler.com/partner/abroad/roadmap/v1/',
		razorpay_key: 'rzp_live_S9j2171Xuw70MD',
		adminBaseUrl: 'https://developmentapis.mindler.com/partner/admin/v1/',
		profileBuidlerUrl: 'http://profilebuidlerqa.mindler.com/applicationStatus',
		supportedLoginUrl: 'https://developmentapis.mindler.com/partner/supportedLogin/v1/',
		pprofile_builder_url: "http://pdevprofilebuilder.mindler.com",
		nexus_url:"https://developmentapis.mindler.com/partner/nexus/v1/"
	},
	// local: {
	// 	baseUrl: 'http://localhost:3000/partner/api/v1/',
	// 	MINDLER_PROFILE_BUILDER_API_URL: `http://localhost:3000/api/profileBuilder/v1`,
	// 	roadmapBaseUrl: 'http://localhost:3000/partner/roadmap/v1/',
	// 	abroadRoadmapBaseUrl: 'http://localhost:3000/partner/abroad/roadmap/v1/',
	// 	razorpay_key: 'rzp_live_S9j2171Xuw70MD',
	// 	adminBaseUrl: 'http://localhost:3000/partner/admin/v1/',
	// 	profileBuilderUrl: 'http://profilebuidlerqa.mindler.com/applicationStatus',
	// 	supportedLoginUrl: 'http://localhost:3000/partner/supportedLogin/v1/'
	// },
	staging: {
		baseUrl: 'https://developmentapis.mindler.com/partner/api/v1/',
		MINDLER_PROFILE_BUILDER_API_URL: `https://api.mindler.com/api/profileBuilder/v1`,
		roadmapBaseUrl: 'https://developmentapis.mindler.com/partner/roadmap/v1/',
		abroadRoadmapBaseUrl: 'https://developmentapis.mindler.com/partner/abroad/roadmap/v1/',
		razorpay_key: 'rzp_live_S9j2171Xuw70MD',
		adminBaseUrl: 'https://developmentapis.mindler.com/partner/admin/v1/',
		profileBuidlerUrl: 'http://profilebuidlerqa.mindler.com/applicationStatus',
		supportedLoginUrl: 'https://developmentapis.mindler.com/partner/supportedLogin/v1/',
		pprofile_builder_url: "http://pdevprofilebuilder.mindler.com",
		nexus_url: "https://developmentapis.mindler.com/partner/nexus/v1/"
	},
	prod: {
		baseUrl: 'https://apis.mindler.com/partner/api/v1/',
		MINDLER_PROFILE_BUILDER_API_URL: `https://api.mindler.com/api/profileBuilder/v1`,
		roadmapBaseUrl: 'https://apis.mindler.com/partner/roadmap/v1/',
		abroadRoadmapBaseUrl: 'https://apis.mindler.com/partner/abroad/roadmap/v1/',
		razorpay_key: 'rzp_live_S9j2171Xuw70MD',
		adminBaseUrl: 'https://apis.mindler.com/partner/admin/v1/',
		profileBuidlerUrl: 'http://partnerprofilebuidler.mindler.com/applicationStatus',
		supportedLoginUrl: 'https://apis.mindler.com/partner/supportedLogin/v1/',
		pprofile_builder_url: "http://pprofilebuilder.mindler.com",
		nexus_url: "https://apis.mindler.com/partner/nexus/v1/"
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
