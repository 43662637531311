import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { validateEmail } from '../../custom_validators/registration.validator';
import { ValidationsService } from '../../services/common/validations.service';
import { PreloginService } from '../../services/prelogin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-partner-registration',
  templateUrl: './partner-registration.component.html',
  styleUrls: ['./partner-registration.component.scss']
})
export class PartnerRegistrationComponent implements OnInit {
  UploadSpecifiedFormat: boolean = false;

  constructor(private activatedRoutes: ActivatedRoute, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private validationService: ValidationsService, private preLoginService: PreloginService) { }

  // flags
  step = 1;
  buttonDisabled = false;
  getStepPostData: any = {};

  isEmailAlreadyUsed = false;
  isErroredOut = false;

  isDomainAlreadyUsed = true;
  domainAlreadyUsedMessage = '';

  isLargeImage = false;
  isImageUploaded = false;
  uploadedImageName = "";

  url: any;

  // password toggle
  isFieldTypeText = false;

  // flag is set to true on button click, and is does not indicates that form has been submitted 
  isStepOneFormSubmitted = false;
  isStepTwoFormSubmitted = false;
  // flags end

  // Forms
  signUpForm_StepOne: FormGroup;
  signUpForm_StepTwo: FormGroup;

  color_1: string;
  color_2: string;



  // resend verification email
  resendEmailVerificationForm: FormGroup;
  isResendEmailVerificationFormSubmitted = false;
  isShowVerificationField = false;
  emailSentToAddress;
  resendEmailLabel = 'Resend Verification Email';
  newEmailAlreadyExists = false;

  domainAvailabeTimer;

  checkIfEmailVerifiedTimer = interval(8000);
  intervalSubscription;
  zohoData: any = {}

  ngOnInit() {

    this.activatedRoutes.queryParams.subscribe(params => {
      this.zohoData.utm_source = params['utm_source'] || null;
      this.zohoData.utm_medium = params['utm_medium'] || null;
      this.zohoData.utm_campaign = params['utm_campaign'] || null;
      this.zohoData.utm_term = params['utm_term'] || null;
      this.zohoData.utm_content = params['utm_content'] || null;
      this.zohoData.utm_keyword = params['utm_keyword'] || null;
    });

    // get current step number on refresh
    if (localStorage.getItem('signUpEmail') && localStorage.getItem('signUpStep')) {
      this.getStepPostData.email = localStorage.getItem('signUpEmail');
      this.getStepPostData.step = parseInt(localStorage.getItem('signUpStep')) - 1;
      this.emailSentToAddress = this.getStepPostData.email;

      this.preLoginService.registerPartner_Step1(this.getStepPostData).subscribe(response => {
        console.log(response);
        if (response.statusCode === 200 && response.status === true) {
          this.step = response.data.step;
          localStorage.setItem('signUpStep', response.data.step);
        }
      });
    }



    // redirect already logged in user to dashboard
    if (localStorage.getItem('pp_session') != null) {
      this.router.navigate(['/my-dashboard']);
    }

    this.color_1 = '#007fb6';
    this.color_2 = '#ea6915';

    this.signUpForm_StepOne = this.formBuilder.group({
      step: ['1'],
      name: ['', [Validators.required, Validators.maxLength(70), Validators.pattern('[(A-Za-z0-9 )]+')]],
      mobile: ['', Validators.required],
      email: ['', [Validators.required, this.validationService.validateEmail]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('.*[0-9].*')]],
      orgnization: ['', Validators.required]
    });

    this.signUpForm_StepTwo = this.formBuilder.group({
      step: ['2'],
      email: [''],
      domain_name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
      image: [''],
      primary_color: ['#007fb6'],
      secondary_color: ['#ea6915']
    }); 

    this.resendEmailVerificationForm = this.formBuilder.group({
      step: ['3'],
      email: [''],
      new_email: ['', [Validators.required, this.validationService.validateEmail]]
    });

    this.domain_name.valueChanges.subscribe(value => {
      if (!this.isDomainAlreadyUsed) {
        this.isDomainAlreadyUsed = true;
      }
    });

    /*get parameters from url and set*/
    this.route.queryParams.subscribe(params => {
      if (params.name) {
        this.signUpForm_StepOne.get("name").setValue(params.name);
      }

      if (params.email) {
        this.signUpForm_StepOne.get("email").setValue(params.email);
      }

      if (params.mobile) {
        this.signUpForm_StepOne.get("mobile").setValue(params.mobile);
      }

    });

  }


  // getters
  // Sign up Step 1
  get name() { return this.signUpForm_StepOne.get('name'); }
  get email() { return this.signUpForm_StepOne.get('email'); }
  get mobile() { return this.signUpForm_StepOne.get('mobile'); }
  get password() { return this.signUpForm_StepOne.get('password'); }
  get brandName() { return this.signUpForm_StepOne.get('orgnization'); }

  // Sign up Step 1
  get domain_name() { return this.signUpForm_StepTwo.get('domain_name'); }
  get image() { return this.signUpForm_StepTwo.get('image'); }

  // Email verification
  get new_email() { return this.resendEmailVerificationForm.get('new_email'); }


  togglePasswordFieldType() {
    this.isFieldTypeText = !this.isFieldTypeText;
  }



  // Step 1 submit
  signUpForm_StepOneSubmit() {

    let zohoObj = { ...this.zohoData }
    zohoObj['leader_source'] = 'partner';

    this.signUpForm_StepOne.markAllAsTouched();
    this.isStepOneFormSubmitted = true;

    if (this.signUpForm_StepOne.invalid) {
      return;
    }


    let data = this.signUpForm_StepOne.value;
    data = { ...data, ...zohoObj };
    // JSON.stringify(this.signUpForm_StepOne.value)
    this.preLoginService.registerPartner_Step1(data).subscribe(response => {
      if (response.statusCode === 200 && response.status === false) {
        this.isEmailAlreadyUsed = response.message;
      }
      else if (response.statusCode === 500 && response.status === false) {
        this.isErroredOut = response.message;
      }
      else if (response.statusCode === 200 && response.status === true) {
        this.isEmailAlreadyUsed = false;
        this.step = response.data.step;
        localStorage.setItem('signUpStep', response.data.step);
        localStorage.setItem('signUpEmail', this.email.value);

        // const date = new Date().getTime();
        // localStorage.setItem('timestamp', '' + date);

        this.resendEmailVerificationForm.patchValue({
          new_email: this.email.value
        });
        this.emailSentToAddress = this.email.value;
      }

    });
  }


  isDomainAvailable(event) {

    //Setting timeout for 1 sec, if user stops typing for a second then a 
    //request will be made instead of waiting for user to go out of input field

    if (this.domain_name.value.length > 0) {

      if (this.domainAvailabeTimer) {
        clearTimeout(this.domainAvailabeTimer);
      }
      this.domainAvailabeTimer = setTimeout(() => {
        const postData = {
          domain_name: this.domain_name.value
        };
        this.preLoginService.isDomainAvailable(postData).subscribe(data => {
          if (data.available) {
            this.buttonDisabled = false;
          } else {
            this.buttonDisabled = true;
          }

          if (data.statusCode == 500 && data.status == false) {
            this.isErroredOut = data.message;

          }
          else {
            this.isDomainAlreadyUsed = data.available;
            this.domainAlreadyUsedMessage = data.message;

          }
        });
      }, 1000);
    }
  }


  uploadImage(event) {
    this.isLargeImage = false;
    if (event.target.files[0].size > 500000) {
      this.isLargeImage = true;
    }
    else {
      this.isLargeImage = false;
      const userEmail = localStorage.getItem('signUpEmail');
      const fileName = event.target.files[0].name;
      var fileNameparts = fileName.split(".");

      //Checking if user uploaded only specific format of file
      if (fileNameparts[fileNameparts.length - 1].toLowerCase() != "png" && fileNameparts[fileNameparts.length - 1].toLowerCase() != "jpeg") {
        this.UploadSpecifiedFormat = true;
        this.isLargeImage = true;
        event.target.value = [];
        return;
      }
      const newFileName = fileName.replace(/ /g, '-');

      const uploadData = new FormData();
      uploadData.append('image', event.target.files[0], newFileName);
      uploadData.append('email', userEmail);

      this.preLoginService.uploadImage(uploadData).subscribe(data => {
        this.isImageUploaded = true;
        this.uploadedImageName = data.name;
        this.signUpForm_StepTwo.patchValue({
          image: this.uploadedImageName
        });
      });
    }
  }

  //When user chooses to get back to previous page from page-2
  setStepBack() {
    this.step = 1;
    console.log(this.isEmailAlreadyUsed);
  }


  removeImage() {
    const postData = {
      email: localStorage.getItem('signUpEmail')
    };

    this.preLoginService.removeImage(postData).subscribe(data => {
      this.isImageUploaded = false;
      this.signUpForm_StepTwo.patchValue({
        image: ['']
      });

    });
  }


  // Step 2 submit
  signUpForm_StepTwoSubmit() {
    this.isStepTwoFormSubmitted = true;

    if (this.signUpForm_StepTwo.invalid) {
      return;
    }
    if (!this.isDomainAlreadyUsed) {
      return;
    }

    this.signUpForm_StepTwo.patchValue({
      primary_color: this.color_1,
      secondary_color: this.color_2,
      email: localStorage.getItem('signUpEmail')
    });

    this.preLoginService.registerPartner_Step2(this.signUpForm_StepTwo.value).subscribe(data => {
      if (data.statusCode == 500 && data.status == false) {
        this.isErroredOut = data.message;
      }
      else {
        if (localStorage.getItem('signUpEmail')) {
          this.resendEmailVerificationForm.patchValue({
            new_email: localStorage.getItem('signUpEmail')
          });
          this.emailSentToAddress = localStorage.getItem('signUpEmail');
        }
        this.step = data.data.step;

        const userData = {
          email: localStorage.getItem('signUpEmail'),
          step: localStorage.getItem('signUpStep')
        }

        // this.intervalSubscription = this.checkIfEmailVerifiedTimer.subscribe(() => {
        //   this.preLoginService.registerPartner_Step1(userData).subscribe(response => {
        //     if (response.statusCode === 200 && response.status === false && response.message === 'Email already registered.') {
        //       this.unSubscribeIntervalTimer();
        //       localStorage.clear();
        //       this.router.navigate(["/login"]);
        //     }
        //   });
        // });
        localStorage.setItem('signUpStep', data.data.step);
      }
    });
  }


  // Email verification
  showVerificationField() {
    this.isShowVerificationField = !this.isShowVerificationField;
    if (this.isShowVerificationField) {
      this.isResendEmailVerificationFormSubmitted = false;
      this.newEmailAlreadyExists = false;
    }
  }

  emailVerificationFormSubmit() {
    this.isResendEmailVerificationFormSubmitted = true;
    this.resendEmailLabel = 'Sending...';

    if (this.isShowVerificationField == true) {
      if (this.resendEmailVerificationForm.invalid) {
        return;
      }
      else if (localStorage.getItem('signUpEmail')) {
        this.resendEmailVerificationForm.patchValue({
          email: localStorage.getItem('signUpEmail'),
          new_email: this.new_email.value
        });
      }
    }

    this.resendEmailVerificationForm.patchValue({
      step: '3'
      // email: localStorage.getItem('signUpEmail')
    });

    this.preLoginService.sendVerificationEmail(this.resendEmailVerificationForm.value).subscribe(response => {
      if (response.statusCode === 200 && response.status === true) {
        this.resendEmailLabel = 'Email Sent';

        if (this.new_email.value !== '') {
          let stepOneData = this.new_email.value;
          this.emailSentToAddress = this.new_email.value;
          localStorage.setItem('signUpEmail', stepOneData);
        }
        else {
          this.emailSentToAddress = localStorage.getItem('signUpEmail');
        }


        this.resendEmailVerificationForm.reset();
        this.isShowVerificationField = false;

        // this.updateBtnText();
        this.resendEmailLabel = 'Resend Verification Email';

      }
      else if (response.statusCode === 200 && response.status === false) {
        this.newEmailAlreadyExists = response.message;
        this.resendEmailLabel = 'Resend Verification Email';
      }
    },
      error => {
        this.resendEmailLabel = 'Resend Verification Email';
      });
  }

  unSubscribeIntervalTimer() {
    this.intervalSubscription.unsubscribe();
  }

  updateBtnText() {
    this.resendEmailLabel = 'Resend Verification Email';
  }

  // Telephone Input
  getNumber(fullNumber) {
    console.log("full",fullNumber)
    this.signUpForm_StepOne.patchValue({
      mobile: fullNumber
    });
  }


  // hasError(event: any): void {
  //   if (!event && this.mobile.value !== '') {
  //     this.mobile.setErrors({ invalidPhoneNumber: true });
  //   }
  // }
  hasError(event: any): void {
		const phoneNumberValue = this.mobile.value;
		if (phoneNumberValue) {
			if (phoneNumberValue.length > 14) {
				this.mobile.setErrors({ invalidPhoneNumber: true });
			} else {
				// Clear the error if the number is within the valid range.
				this.mobile.setErrors(null);
			}
		}
	}
  
}
