import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ValidationsService } from "../../services/common/validations.service";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "app-organization-details",
  templateUrl: "./organization-details.component.html",
  styleUrls: ["./organization-details.component.scss"],
})
export class OrganizationDetailsComponent implements OnInit {
  isLoading = true;
  dialCode = "91";
  iso2 = "in";
  organizationDetailsForm: FormGroup;
  isOrganizationDetailsFormSubmitted: boolean;

  saveBtnLabel = "Save Changes";
  isBtnDisabled = true;
  //Variable to keep track if country field in mobile number getting patched for first time or not, if it is first time we are not emitting event
  firstPatchForMobileNo = false;

  websiteAddress;

  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationsService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    const regForGst =
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
    this.organizationDetailsForm = this.formBuilder.group({
      orgnization_name: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 ]*$"),
          Validators.maxLength(50),
        ],
      ],
      helpline_number: [""],
      official_email_id: ["", Validators.email],
      office_address: ["",[Validators.required,Validators.pattern("^[ A-Za-z0-9_.,/:&-\s\r\n]*$")]],
      google_map_link: [""],
      facebook_page: ["", [Validators.pattern(reg)]],
      instagram_page: ["", [Validators.pattern(reg)]],
      linkedin_page: ["", [Validators.pattern(reg)]],
      gst: ["", Validators.pattern(regForGst)],
      dialCode: [this.dialCode],
      iso2: [this.iso2],
    });

    // this.organizationDetailsForm.addControl('dialCode', new FormControl(''));
    //this.organizationDetailsForm.addControl('iso2', new FormControl(''));

    this.getOrganizationDetails();

    //
    this.organizationDetailsForm.valueChanges.subscribe((val) => {
      this.saveBtnLabel = "Save Changes";
      this.isBtnDisabled = false;
    });
  }

  // getters
  get organization_name() {
    return this.organizationDetailsForm.get("orgnization_name");
  }
  get helpline_number() {
    return this.organizationDetailsForm.get("helpline_number");
  }
  get official_email_id() {
    return this.organizationDetailsForm.get("official_email_id");
  }
  get facebook_page() {
    return this.organizationDetailsForm.get("facebook_page");
  }
  get instagram_page() {
    return this.organizationDetailsForm.get("instagram_page");
  }
  get linkedin_page() {
    return this.organizationDetailsForm.get("linkedin_page");
  }
  get office_address(){
    return this.organizationDetailsForm.get('office_address');
  }
  get gst() {
    return this.organizationDetailsForm.get("gst");
  }

  getOrganizationDetails() {
    this.isLoading = true;

    this.dashboardService.getOrganizationDetails().subscribe((response) => {
      this.isLoading = false;
      if (response.statusCode == 200 && response.status == true) {
        if (response.data.details.length > 0) {
          this.iso2 = response.data.details[0].iso2
            ? response.data.details[0].iso2
            : "in";
          this.organizationDetailsForm.patchValue(
            {
              orgnization_name: response.data.details[0].orgnization_name,
              dialCode: response.data.details[0].dialCode,
              helpline_number: response.data.details[0].helpline_number,
              iso2: response.data.details[0].iso2,
              official_email_id: response.data.details[0].official_email_id,
              office_address: response.data.details[0].office_address,
              google_map_link: response.data.details[0].google_map_link,
              facebook_page: response.data.details[0].facebook_page,
              instagram_page: response.data.details[0].instagram_page,
              linkedin_page: response.data.details[0].linkedin_page,
              gst: response.data.details[0].gst,
            },
            { emitEvent: false }
          );
          //  the valueChanges will not run for this as set to false (we want it for the first time, button Label text)
        }
        this.websiteAddress = response.data.domain_name;
      }
    });
  }

  organizationDetailsFormSubmit() {
    // Trimming the string to remove leading and trailing whitespaces
    this.organization_name.setValue(this.organizationDetailsForm.value.orgnization_name.trim());
    this.organizationDetailsForm.markAllAsTouched();
    if (this.organizationDetailsForm.invalid) {
      return;
    }

    this.saveBtnLabel = "Saving...";

    this.dashboardService
      .setOrganizationDetails(this.organizationDetailsForm.value)
      .subscribe((response) => {
        if (response.statusCode == 500 && response.status == false) {
        } else {
          this.saveBtnLabel = "Saved";
          setTimeout(() => {
            this.saveBtnLabel = "Save Changes";
            this.isBtnDisabled = true;
          }, 3000);
        }
      });
  }

  telInputObject(obj) {
    this.firstPatchForMobileNo = true;
    obj.setCountry(this.iso2);
  }

  onCountryChange(event) {
    this.dialCode = event.dialCode;
    this.iso2 = event.iso2;

    this.organizationDetailsForm.patchValue({
      dialCode: event.dialCode,
      iso2: event.iso2,
    },{emitEvent : !this.firstPatchForMobileNo});
    this.firstPatchForMobileNo = false;
    console.log(event);
  }

  // Telephone Input
  getNumber(fullNumber) {
    console.log(fullNumber);
    this.organizationDetailsForm.patchValue({
      phone: this.dialCode + "-" + fullNumber,
    });
  }

  hasError(event: any): void {
    if (!event && this.helpline_number.value !== "") {
      this.helpline_number.setErrors({ invalidPhoneNumber: true });
    }
  }
}
