import { Component, OnInit } from '@angular/core';
import { PreloginService } from '../../services/prelogin.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {


  dashboardBtnLabel = 'Log In to Dashboard';

  constructor(private location: Location, private preLoginService: PreloginService) {
    if (this.preLoginService.isUserLoggedIn()) {
      // console.log('logged in');
      this.preLoginService.setLoggedInFlagForNav(false);
      this.dashboardBtnLabel = 'Go to Dashboard';
    }
    else {
      // console.log('not logged in');
      this.dashboardBtnLabel = 'Log In to Dashboard';

    }
  }

  ngOnInit() { }

  // goBack() {
  //   this.location.back();
  // }

}
