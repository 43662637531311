import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuard } from './auth.guard';
// Prime-Ng Components
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { InputMaskModule } from 'primeng/inputmask';
import { EditorModule } from 'primeng/editor';  // uses quill.js
import { ChartModule } from 'primeng/chart'; // uses chart.js
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';

// Other External Components
import { ColorPickerModule } from 'ngx-color-picker';
import { Ng2TelInputModule } from 'ng2-tel-input';

// Dashboard
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { BrandingComponent } from './dashboard/branding/branding.component';
import { InformationComponent } from './dashboard/information/information.component';
import { TestimonialsComponent } from './dashboard/testimonials/testimonials.component';

// Prelogin New
import { PartnerRegistrationComponent } from './prelogin/partner-registration/partner-registration.component';
import { CounsellorRegistrationComponent } from './prelogin/counsellor-registration/counsellor-registration.component';
import { LoginComponent } from './prelogin/login/login.component';

import { OrganizationDetailsComponent } from './dashboard/organization-details/organization-details.component';
import { MyTeamComponent } from './dashboard/my-team/my-team.component';
import { TeamMemberRolePipe } from './pipes/dashboard/team-member-role.pipe';
// tslint:disable-next-line: max-line-length
import { CounsellorSignupPreprocessingComponent } from './prelogin/counsellor-signup-preprocessing/counsellor-signup-preprocessing.component';
import { ResetPasswordComponent } from './prelogin/reset-password/reset-password.component';

import { MyDashboardComponent } from './dashboard/my-dashboard/my-dashboard.component';
import { AssessmentCouponsComponent } from './dashboard/assessment-coupons/assessment-coupons.component';
import { UpgradeComponent } from './dashboard/upgrade/upgrade.component';
import { PurchaseAssessmentCouponsComponent } from './dashboard/shared/purchase-assessment-coupons/purchase-assessment-coupons.component';
import { BillingInvoiceComponent } from './dashboard/billing-invoice/billing-invoice.component';
import { ForgotPasswordComponent } from './prelogin/forgot-password/forgot-password.component';
import { BillingFrequencyPipe } from './pipes/dashboard/billing-frequency.pipe';
import { StudentProfileComponent } from './dashboard/student-profile/student-profile.component';
import { InfoComponent } from './dashboard/student-profile-partials/info/info.component';
import { TestsComponent } from './dashboard/student-profile-partials/tests/tests.component';
import { MatchesComponent } from './dashboard/student-profile-partials/matches/matches.component';
import { NotesComponent } from './dashboard/student-profile-partials/notes/notes.component';
import { StudentListComponent } from './dashboard/student-list/student-list.component';
import { PlanActionModalsComponent } from './dashboard/shared/plan-action-modals/plan-action-modals.component';
import { OsTestParameterPipe } from './pipes/dashboard/os-test-parameter.pipe';
import { AssessmentTypePipe } from './pipes/dashboard/assessment-type.pipe';
import { IconsModule } from './icons/icons.module';
import { ExamCornerComponent } from './dashboard/exam-corner/exam-corner.component';
import { ExamCornerDetailsComponent } from './dashboard/exam-corner-details/exam-corner-details.component';

import { MyInterceptor } from './auth.interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ScholarshipsComponent } from './dashboard/scholarships/scholarships.component';
import { RoadmapComponent } from './dashboard/roadmap/roadmap.component';
import { PreferencesComponent } from './dashboard/roadmap-partials/preferences/preferences.component';
import { CollegeMapComponent } from './dashboard/roadmap-partials/college-map/college-map.component';
import { ExamShortlistComponent } from './dashboard/roadmap-partials/exam-shortlist/exam-shortlist.component';
import { ChangePasswordComponent } from './dashboard/change-password/change-password.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { SavedRoadmapsComponent } from './dashboard/roadmap-partials/saved-roadmaps/saved-roadmaps.component';

import { NetworkService } from './services/network.service';
import { LockScreenComponent } from './dashboard/lock-screen/lock-screen.component';
import { CareerProfileComponent } from './dashboard/student-profile-partials/career-profile/career-profile.component';

import { DashboardGridComponent } from './dashboard/dashboard-grid/dashboard-grid.component';
import { StudentRoadmapComponent } from './dashboard/student-profile-partials/student-roadmap/student-roadmap.component';
import { VerifyEmailComponent } from './prelogin/verify-email/verify-email.component';
import { ActionPlanComponent } from './dashboard/student-profile-partials/action-plan/action-plan.component';
import { HelpComponent } from './dashboard/help/help.component';
import { AbroadPreferencesComponent } from './dashboard/roadmap-partials/abroad-preferences/abroad-preferences.component';
import { AbroadCollegesComponent } from './dashboard/roadmap-partials/abroad-colleges/abroad-colleges.component';
import { PerformanceReportComponent } from './dashboard/performance-report/performance-report.component';
import { InsightsComponent } from './dashboard/student-profile-partials/insights/insights.component';
import { WhitelabellingOptionsComponent } from './dashboard/whitelabelling-options/whitelabelling-options.component';
import { AdminLoginComponent } from './prelogin/admin-login/admin-login.component';
import { PartnersOverviewComponent } from './superadmin/partners-overview/partners-overview.component';
import { PartnerDetailsComponent } from './superadmin/partner-details/partner-details.component';
import { AdminBrandingComponent } from './superadmin/admin-branding/admin-branding.component';
import { AdminInformationComponent } from './superadmin/admin-information/admin-information.component';
import { AdminTestimonialsComponent } from './superadmin/admin-testimonials/admin-testimonials.component';
import { AdminMyTeamComponent } from './superadmin/admin-my-team/admin-my-team.component';
import { AdminOrganizationDetailsComponent } from './superadmin/admin-organization-details/admin-organization-details.component';
import { AdminPartnerInfoComponent } from './superadmin/admin-partner-info/admin-partner-info.component';
import { AdminSubscriptionComponent } from './superadmin/admin-subscription/admin-subscription.component';
import { AdminAssessmentCouponsComponent } from './superadmin/admin-assessment-coupons/admin-assessment-coupons.component';
import { AdminPaymentComponent } from './superadmin/admin-payment/admin-payment.component';
import { ReplacePipe } from './pipes/superadmin/replace.pipe';
//import { MarketingResourcesComponent } from './dashboard/marketing-resources/marketing-resources.component';
import { AdminMarketingResourcesComponent } from './superadmin/marketing-resources/marketing-resources.component';
import { ResourcesComponent } from './dashboard/resources/resources.component';
import { RevenuePipe } from './pipes/superadmin/revenue.pipe';
import { LoginWithMindlerTokenComponent } from './dashboard/login-with-mindler-token/login-with-mindler-token.component';
import { GenerateCouponsComponent } from './dashboard/shared/generate-coupons/generate-coupons.component';
import { PurchaseVipComponent } from './dashboard/shared/purchase-vip/purchase-vip.component';
import { VipComponent } from './dashboard/student-profile-partials/vip/vip.component';
import { AdminProductAccessManagementComponent } from './superadmin/admin-product-access-management/admin-product-access-management.component';
import { AdminVirtualInternshipsComponent } from './superadmin/admin-virtual-internships/admin-virtual-internships.component';
import { AdminZohoSubscriptionComponent } from './superadmin/admin-zoho-subscription/admin-zoho-subscription.component';
import { DocumentArchivesComponent } from './dashboard/student-profile-partials/document-archives/document-archives.component';

// export function initApp(http: HttpClient) {
//   return () => {
//     return console.log('hiiiiiiiiiii');
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    BrandingComponent,
    InformationComponent,
    TestimonialsComponent,
    PartnerRegistrationComponent,
    CounsellorRegistrationComponent,
    LoginComponent,
    OrganizationDetailsComponent,
    MyTeamComponent,
    TeamMemberRolePipe,
    CounsellorSignupPreprocessingComponent,
    ResetPasswordComponent,
    MyDashboardComponent,
    AssessmentCouponsComponent,
    UpgradeComponent,
    PurchaseAssessmentCouponsComponent,
    BillingInvoiceComponent,
    ForgotPasswordComponent,
    BillingFrequencyPipe,
    StudentProfileComponent,
    InfoComponent,
    TestsComponent,
    MatchesComponent,
    NotesComponent,
    StudentListComponent,
    PlanActionModalsComponent,
    OsTestParameterPipe,
    AssessmentTypePipe,
    ExamCornerComponent,
    ExamCornerDetailsComponent,
    PageNotFoundComponent,
    ScholarshipsComponent,
    RoadmapComponent,
    PreferencesComponent,
    CollegeMapComponent,
    ExamShortlistComponent,
    ChangePasswordComponent,
    ProfileComponent,
    SavedRoadmapsComponent,
    LockScreenComponent,
    CareerProfileComponent,
    DashboardGridComponent,
    StudentRoadmapComponent,
    VerifyEmailComponent,
    ActionPlanComponent,
    HelpComponent,
    AbroadPreferencesComponent,
    AbroadCollegesComponent,
    PerformanceReportComponent,
    InsightsComponent,
    WhitelabellingOptionsComponent,
    AdminLoginComponent,
    PartnersOverviewComponent,
    PartnerDetailsComponent,
    AdminBrandingComponent,
    AdminInformationComponent,
    AdminTestimonialsComponent,
    AdminMyTeamComponent,
    AdminOrganizationDetailsComponent,
    AdminPartnerInfoComponent,
    AdminSubscriptionComponent,
    AdminAssessmentCouponsComponent,
    AdminPaymentComponent,
    ReplacePipe,
    AdminMarketingResourcesComponent,
    ResourcesComponent,
    RevenuePipe,
    LoginWithMindlerTokenComponent,
    GenerateCouponsComponent,
    PurchaseVipComponent,
    VipComponent,
    AdminProductAccessManagementComponent,
    AdminVirtualInternshipsComponent,
    AdminZohoSubscriptionComponent,
    DocumentArchivesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    ProgressSpinnerModule,
    HttpClientModule,
    DropdownModule,
    MultiSelectModule,
    PaginatorModule,
    InputMaskModule,
    EditorModule,
    ChartModule,
    CalendarModule,
    IconsModule,
    AutoCompleteModule,
    TooltipModule,
    Ng2TelInputModule,
    ToastModule,
    InputTextModule,
    DialogModule   

  ],
  providers: [AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/partner' },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initApp,
    //   multi: true,
    //   deps: [HttpClient]
    // }
    NetworkService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ns: NetworkService) => function () { return ns.setUrl(); },
      deps: [NetworkService],
      multi: true
    },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
