import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { Observable, Subject } from 'rxjs';
import { ExportToCsv } from 'export-to-csv';
import { NetworkService } from './network.service';

import { LazyLoadFilesService } from './lazy-load-files.service';

@Injectable({
  providedIn: 'root'
})
export class SuperadminService extends NetworkService {

  baseUrl;
  razorpayKey;
  baseUrls;

  private complimentaryCouponsAddedSubject = new Subject<any>();
  private partnerName = new Subject<any>();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  httpOptionsImg = {
    headers: new HttpHeaders({
    })
  };

  changesOccur = new Subject()

  constructor(private http: HttpClient, private lazyLoadService: LazyLoadFilesService) {
    super();
    // ADMIN base url
    this.baseUrl = (this.getBaseUrls()).adminBaseUrl;
    this.baseUrls = (this.getBaseUrls()).baseUrl;
    this.razorpayKey = (this.getBaseUrls()).razorpay_key;
  }

  getRazorpayKey() {
    return this.razorpayKey;
  }


  // Login is implemented in PreLogin Service.

  // Overview
  getPartnersOverviewData(data) {
    return this.http.post<any>(this.baseUrl + 'dashboard', data, this.httpOptions);
  }

  // Specific Partner Details
  getPartnerInfoAndStats(data) {
    return this.http.post<any>(this.baseUrl + 'partnerDetails', data, this.httpOptions);
  }

  updateProfileInfo(data) {
    return this.http.post<any>(this.baseUrl + 'updateBasicInfo', data, this.httpOptions);
  }
  

  postZohoSubscriptionAutomation(data) {
    return this.http.post<any>(this.baseUrl + 'zohoSubscriptionAutomation', data, this.httpOptions);
  }

  resetPartnerPassword(data) {
    return this.http.post<any>(this.baseUrl + 'updatePartnerPassword', data, this.httpOptions);
  }

  getOrganizationDetails(data) {
    return this.http.post<any>(this.baseUrl + 'getPartnerOrgnizationDetails', data, this.httpOptions);
  }

  setOrganizationDetails(data) {
    return this.http.post<any>(this.baseUrl + 'updatePartnerOrgnizationDetails', data, this.httpOptions);
  }

  getPartnerSubscriptionDetails(data) {
    return this.http.post<any>(this.baseUrl + 'partnerSubscriptionDetails', data, this.httpOptions);
  }

  createManualPartnerSubscription(data) {
    return this.http.post<any>(this.baseUrl + 'createSusctiptionForPartner', data, this.httpOptions);
  }

  getPartnerCouponsList(data) {
    return this.http.post<any>(this.baseUrl + 'getPartnerCoupons', data, this.httpOptions);
  }

  assignPurchasedCoupon(data) {
    return this.http.post<any>(this.baseUrl + 'generatePartnersCoupon', data, this.httpOptions);
  }

  addComplimentaryCoupons(data) {
    return this.http.post<any>(this.baseUrl + 'addComplimentryCoupons', data, this.httpOptions);
  }

  deleteCoupon(data) {
    console.log("Came here");
    return this.http.post<any>(this.baseUrl + 'removeCoupon', data, this.httpOptions);
  }

  // Observable methods for ComplimentaryCoupons Added Success
  setComplimentaryCouponsAddedFlag(data) {
    this.complimentaryCouponsAddedSubject.next(data);
  }

  getComplimentaryCouponsAddedFlag(): Observable<any> {
    return this.complimentaryCouponsAddedSubject.asObservable();
  }

  setPartnerName(data) {
    this.partnerName.next(data);
  }

  getPartnerName(): Observable<any> {
    return this.partnerName.asObservable();
  }


  getPartnerTeamList(data) {
    return this.http.post<any>(this.baseUrl + 'getPartnerTeam', data, this.httpOptions);
  }

  getTeamListForCsvDownload(data) {
    return this.http.post<any>(this.baseUrl + 'downloadTeamCsv', data, this.httpOptions);
  }

  teamMemberAction(data) {
    return this.http.post<any>(this.baseUrl + 'markPartnersCounselor', data, this.httpOptions);
  }

  updateMember(data) {
    return this.http.post<any>(this.baseUrl + 'updateCounselorData', data, this.httpOptions);
  }

  sendResetPasswordLink(data) {
    return this.http.post<any>(this.baseUrl + 'sendResetPasswordLinkToCounselor', data, this.httpOptions);
  }

  uploadMemberImage(data) {
    return this.http.post<any>(this.baseUrl + 'uploadCounselorImage', data, this.httpOptionsImg);
  }

  getPartnerData(data) {
    return this.http.post<any>(this.baseUrl + 'getPartnerData', data, this.httpOptions);
  }

  setBrandingDetails(data) {
    return this.http.post<any>(this.baseUrl + 'updatePartnersBranding', data, this.httpOptions);
  }

  uploadImage(data) {
    return this.http.post<any>(this.baseUrl + 'uploadPartnerImage', data);
  }

  setInformationDetails(data) {
    return this.http.post<any>(this.baseUrl + 'updatePartnerWebsiteInformation', data, this.httpOptions);
  }

  setTestimonialsDetails(data) {
    return this.http.post<any>(this.baseUrl + 'addPartnerTestimonials', data, this.httpOptions);
  }

  removeTestimonial(data) {
    return this.http.post<any>(this.baseUrl + 'removePartnerTestimonial', data, this.httpOptions);
  }

  createPaymentLink(data) {
    return this.http.post<any>(this.baseUrl + 'createInvoice', data, this.httpOptions);
  }

  getAllPayments(data) {
    return this.http.post<any>(this.baseUrl + 'getInvoices', data, this.httpOptions);
  }

  deactivateSubscription(data) {
    return this.http.post<any>(this.baseUrl + 'stopSubscription', data, this.httpOptions);
  }

  reactivateSubscription(data) {
    return this.http.post<any>(this.baseUrl + 'reactivateSubscription', data, this.httpOptions);
  }

  updateDefaultPrefix(data) {
    return this.http.post<any>(this.baseUrl + 'updateCustomPrefix', data, this.httpOptions);
  }

  partnerlist() {
    return this.http.get<any>(this.baseUrl + 'getPartners', this.httpOptions);
  }

  addresources(data) {
    return this.http.post<any>(this.baseUrl + 'addresources', data);
  }

  addFolder(data) {
    return this.http.post<any>(this.baseUrl + 'addFolder', data, this.httpOptions);
  }

  resourcelist(data) {
    return this.http.post<any>(this.baseUrl + 'resourcelist', data, this.httpOptions);
  }

  resourcelistbyid(data) {
    return this.http.post<any>(this.baseUrl + 'getresourcelistbyid', data, this.httpOptions);
  }

  folderlist() {
    return this.http.get<any>(this.baseUrl + 'folders', this.httpOptions);
  }

  isFolderAlreadyExist(data) {
    return this.http.post<any>(this.baseUrl + 'checkFolderExistance', data, this.httpOptions);
  }

  viewResourcePartners(data) {
    return this.http.post<any>(this.baseUrl + 'getResourcePartnersList', data, this.httpOptions);
  }

  getFolderBypartner(data) {
    return this.http.post<any>(this.baseUrl + 'getFolderBypartner', data, this.httpOptions);
  }

  // getting all features and services for SuperAdmin
  getallServicesAndFeatures(data) {
    return this.http.post<any>(this.baseUrl + 'allServicesAndFeatures', data , this.httpOptions);
  }
  // update student services 
  updateStudentServices(data){
    return this.http.post<any>(this.baseUrl + 'updatePartnerServices',data, this.httpOptions);
  }
  getpartnerVipPayments(data){
    return this.http.post<any>(this.baseUrl + 'partnerVipPayments',data, this.httpOptions);
  }
  addVipCount(data){
    return this.http.post<any>(this.baseUrl + 'addVip',data, this.httpOptions);
  }
  addCouponCount(data){
    return this.http.post<any>(this.baseUrl + 'addCoupon',data, this.httpOptions);
  }


}
