import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import jwt_decode from "jwt-decode";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { ExportToCsv } from "export-to-csv";
import { NetworkService } from "./network.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService extends NetworkService {
  baseUrl;
  roadmapBaseUrl;
  profileBuilderUrl;
  abroadRoadmapBaseUrl;
  razorpayKey;
  pprofilebuilder;

  // showSidebar = true;
  studentId;

  planChangeRequestFlag;

  couponBoughtChildResponse;

  partnerWhiteLabelStatus = new BehaviorSubject<boolean>(false);

  private planChangeRequestSubject = new Subject<any>();
  private couponBoughtSubject = new Subject<any>();
  private roadmapTabSubject = new Subject<any>();

  private careerLibrarySubject = new Subject<any>();

  private profileImageSubject = new Subject<any>();
  private firstTimePlanPurchaseSubject = new Subject<any>();
  private planBtnDisabled = new Subject<boolean>();
  private $planBtnDisabled = this.planBtnDisabled.asObservable();
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      'access-key': 'U2FsdGVkX1+J6nyjbIOZzBsEQMG5lk07l8TrNLbq2nWoxEUMlfJBsl3NVCMvWdDm'
    })
  };

  httpOptionsImg = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {
    super();
    // console.log('dashboard service cons- ', this.getBaseUrls());
    this.baseUrl = this.getBaseUrls().baseUrl;
    this.roadmapBaseUrl = this.getBaseUrls().roadmapBaseUrl;
    this.profileBuilderUrl = this.getBaseUrls().MINDLER_PROFILE_BUILDER_API_URL + '/isProfileExists'
    this.abroadRoadmapBaseUrl = this.getBaseUrls().abroadRoadmapBaseUrl;
    this.razorpayKey = this.getBaseUrls().razorpay_key;
    this.pprofilebuilder = this.getBaseUrls().pprofile_builder_url;
  }

  getRazorpayKey() {
    return this.razorpayKey;
  }

  getPProfileBuilderUrl(){
    return this.pprofilebuilder;
  }

  decodeToken(): any {
    const token = localStorage.getItem("pp_token");
    return jwt_decode(token);
  }

  // Plan Purchase (1st time) flag START
  setFirstTimePlanPurchaseFlag() {
    this.firstTimePlanPurchaseSubject.next(true);
  }

  getFirstTimePlanPurchaseFlag(): Observable<any> {
    return this.firstTimePlanPurchaseSubject.asObservable();
  }
  // Plan Purchase (1st time) flag END

  uploadImage(data) {
    return this.http.post<any>(this.baseUrl + "uploadImage", data);
  }

  // My Website

  // Get Branding OR Information OR Testimonials Data
  getPartnerData(data) {
    return this.http.post<any>(
      this.baseUrl + "getPartnerData",
      data,
      this.httpOptions
    );
  }

  // Branding
  setBrandingDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "brandingUpdates",
      data,
      this.httpOptions
    );
  }

  // Add Testimonials
  setTestimonialsDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "testimonialsUpdates",
      data,
      this.httpOptions
    );
  }

  // Remove Testimonials
  removeTestimonial(data) {
    return this.http.post<any>(
      this.baseUrl + "testimonialRemove",
      data,
      this.httpOptions
    );
  }

  // White-labelling / Settings
  getWhiteLabellingOptions() {
    return this.http.get<any>(
      this.baseUrl + "getWhiteLabellingOptions",
      this.httpOptions
    );
  }

  setWhiteLabellingOptions(data) {
    return this.http.post<any>(
      this.baseUrl + "whiteLabellingOptions",
      data,
      this.httpOptions
    );
  }

  // Information
  setInformationDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "informationUpdates",
      data,
      this.httpOptions
    );
  }

  // Information Image
  uploadInformationImage(data) {
    return this.http.post<any>(
      this.baseUrl + "informationUpdates",
      data,
      this.httpOptionsImg
    );
  }

  // remove feature photo
  removeFeaturedPhoto(data) {
    return this.http.post<any>(
      this.baseUrl + "removeFeaturedPhoto",
      data,
      this.httpOptionsImg
    );
  }

  // Organization Details
  getOrganizationDetails() {
    return this.http.get<any>(
      this.baseUrl + "getOrgnization",
      this.httpOptions
    );
  }

  setOrganizationDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "postOrgnization",
      data,
      this.httpOptions
    );
  }

  getStudentDocs(student_main_user_id: any) {
    return this.http.post<any>(
      this.baseUrl + "getStudentDocuments",
      { student_main_user_id },
      this.httpOptions
    );
  }

  // My Team
  getTeamList(data) {
    return this.http.post<any>(
      this.baseUrl + "getTeam",
      data,
      this.httpOptions
    );
  }

  sendInvitations(data) {
    return this.http.post<any>(
      this.baseUrl + "sendInvitation",
      data,
      this.httpOptions
    );
  }

  getTeamListForCsvDownload(data) {
    return this.http.post<any>(
      this.baseUrl + "downloadTeamCsv",
      data,
      this.httpOptions
    );
  }

  // Export to CSV
  exportToCsvService(fileName, data) {
    const options = {
      fieldSeparator: ",",
      filename: fileName,
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  teamMemberAction(data) {
    return this.http.post<any>(
      this.baseUrl + "markCounselor",
      data,
      this.httpOptions
    );
  }

  uploadMemberImage(data) {
    return this.http.post<any>(
      this.baseUrl + "uploadCounselorImage",
      data,
      this.httpOptionsImg
    );
  }

  updateMember(data) {
    return this.http.post<any>(
      this.baseUrl + "updateCounselorData",
      data,
      this.httpOptions
    );
  }

  sendResetPasswordLink(data) {
    return this.http.post<any>(
      this.baseUrl + "sendResetPasswordLinkToCounselor",
      data,
      this.httpOptions
    );
  }

  // subscription upgrade
  getSubscriptionDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "subscriptionDetails",
      data,
      this.httpOptions
    );
  }

  createSubscription(data) {
    return this.http.post<any>(
      this.baseUrl + "createSubscription",
      data,
      this.httpOptions
    );
  }

  initiateSubscription(data) {
    return this.http.post<any>(
      this.baseUrl + "initiateSubscription",
      data,
      this.httpOptions
    );
  }

  refreshToken() {
    return this.http.get<any>(this.baseUrl + "refreshToken", this.httpOptions);
  }

  razorpayAuthDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "authDetails",
      data,
      this.httpOptions
    );
  }

  // student list
  getStudentList(data) {
    return this.http.post<any>(
      this.baseUrl + "studentList",
      data,
      this.httpOptions
    );
  }

  isProfileExists(userIds: number[]) {
    return this.http.post<any>(this.profileBuilderUrl, { userIds }, this.httpOptions);
  }

  assignStudentToCounselor(data) {
    return this.http.post<any>(
      this.baseUrl + "assignCounselor",
      data,
      this.httpOptions
    );
  }

  // Student Profile

  // Info
  studentInfo(data) {
    return this.http.post<any>(
      this.baseUrl + "studentDetailedInfo",
      data,
      this.httpOptions
    );
  }

  // Matches
  studentMatches(data) {
    return this.http.post<any>(
      this.baseUrl + "studentMatchesInfo",
      data,
      this.httpOptions
    );
  }

  // Notes
  getStudentNotes(data) {
    return this.http.post<any>(
      this.baseUrl + "studentNotesInfo",
      data,
      this.httpOptions
    );
  }

  addQuickNote(data) {
    return this.http.post<any>(
      this.baseUrl + "addQuickNote",
      data,
      this.httpOptions
    );
  }

  resetStudentPassword(data) {
    return this.http.post<any>(
      this.baseUrl + "resetStudentPassword",
      data,
      this.httpOptions
    );
  }

  addDetailedNote(data) {
    return this.http.post<any>(
      this.baseUrl + "addNote",
      data,
      this.httpOptions
    );
  }

  // Career Profile
  getCareerActivities(data) {
    return this.http.post<any>(
      this.baseUrl + "studentActivitiesInfo",
      data,
      this.httpOptions
    );
  }

  getCareerInsights(data) {
    return this.http.post<any>(
      this.baseUrl + "getInsights",
      data,
      this.httpOptions
    );
  }

  // Tests
  getStudentTestResults(data) {
    return this.http.post<any>(
      this.baseUrl + "studentTestInfo",
      data,
      this.httpOptions
    );
  }

  getStudentSummaryReport(id) {
    return this.http.get<any>(
      this.baseUrl + "generateSummaryReport?uid=" + id,
      this.httpOptions
    );
  }

  getStudentReport(studentId, reportApiDomain) {
    return this.http.get(
      reportApiDomain +
      "/AssessReport/getPdfReportForPartners/" +
      studentId +
      "?token=" +
      localStorage.getItem("pp_token")
    );
  }

  addUpdateServices(data) {
    return this.http.post<any>(
      this.baseUrl + "partnerServicesModification",
      data
    );
  }

  getStudentBasicInfo(data) {
    return this.http.post<any>(
      this.baseUrl + "getStudentBasicInfo",
      data,
      this.httpOptions
    );
  }

  // Action Plan
  getActionPlans(data) {
    return this.http.post<any>(
      this.baseUrl + "getActionPlan",
      data,
      this.httpOptions
    );
  }

  addActionPlan(data) {
    return this.http.post<any>(
      this.baseUrl + "addActionPlan",
      data,
      this.httpOptions
    );
  }

  uploadActionPlanResources(data) {
    return this.http.post<any>(
      this.baseUrl + "uploadActionPlanResources",
      data,
      this.httpOptionsImg
    );
  }

  shareActionPlan(data) {
    return this.http.post<any>(
      this.baseUrl + "shareActionPlan",
      data,
      this.httpOptions
    );
  }

  // Student Roadmap
  // baseUrl is different (raodmapBaseUrl is used)
  getStudentRoadmapId(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getStudentRoadmapId",
      data,
      this.httpOptions
    );
  }

  // Billing & Invoices
  getBillingInfoPageDetails() {
    return this.http.get<any>(
      this.baseUrl + "billingInformation",
      this.httpOptions
    );
  }

  getSubscriptionId() {
    return this.http.get<any>(
      this.baseUrl + "getSubscriptionId",
      this.httpOptions
    );
  }

  updateCardDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "updateCardDetails",
      data,
      this.httpOptions
    );
  }

  updateBillingInformationDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "updateBillingInformation",
      data,
      this.httpOptions
    );
  }

  sendBillOnEmail(data) {
    return this.http.post<any>(
      this.baseUrl + "sendBillOnEmail",
      data,
      this.httpOptions
    );
  }





  getBills(data) {
    return this.http.post<any>(
      this.baseUrl + "getBills",
      data,
      this.httpOptions
    );
  }

  // Upgrade and Billing-Invoices - Common (plan-action-modals)
  updateSubscription(data) {
    return this.http.post<any>(
      this.baseUrl + "updateSubscription",
      data,
      this.httpOptions
    );
  }

  cancelSubscription() {
    return this.http.get<any>(
      this.baseUrl + "cancelSubscription",
      this.httpOptions
    );
  }

  beforeDowngrade(data) {
    return this.http.post<any>(
      this.baseUrl + "beforeDowngrade",
      data,
      this.httpOptions
    );
  }

  disableCounselors(data) {
    return this.http.post<any>(
      this.baseUrl + "disableCounselors",
      data,
      this.httpOptions
    );
  }

  // Observable methods for disable and message if a subscription change request is made
  setPlanChangeRequestFlag(value) {
    this.planChangeRequestFlag = value;
    this.planChangeRequestSubject.next(this.planChangeRequestFlag);
  }

  getPlanChangeRequestFlag(): Observable<any> {
    return this.planChangeRequestSubject.asObservable();
  }

  // Assessment & Codes

  // returns Coupons Stats + Purchase plans pricing table data
  getPartnerCouponDetails() {
    return this.http.get<any>(
      this.baseUrl + "getPartnerCouponDetails",
      this.httpOptions
    );
  }

  getPriceForCoupons(data) {
    return this.http.post<any>(
      this.baseUrl + "getPriceForCoupons",
      data,
      this.httpOptions
    );
  }

  assignAdditionalCoupons(data) {
    return this.http.post<any>(
      this.baseUrl + "assignAdditionalCoupons",
      data,
      this.httpOptions
    );
  }
  assignAdditionalVip(data) {
    return this.http.post<any>(
      this.baseUrl + "assignAdditionalVip",
      data,
      this.httpOptions
    );
  }

  // Observable methods for Coupons Bought Success
  setCouponBoughtFlag(count, amount) {
    this.couponBoughtChildResponse = {
      couponCount: count,
      couponAmount: amount,
    };
    this.couponBoughtSubject.next(this.couponBoughtChildResponse);
  }

  getCouponBoughtFlag(): Observable<any> {
    return this.couponBoughtSubject.asObservable();
  }

  getPurchasedCouponsList(data) {
    return this.http.post<any>(
      this.baseUrl + "getCoupons",
      data,
      this.httpOptions
    );
  }

  assignPurchasedCoupon(data) {
    return this.http.post<any>(
      this.baseUrl + "generateCoupon",
      data,
      this.httpOptions
    );
  }

  // Exam Corner
  getExamFiltersData() {
    return this.http.get<any>(
      this.baseUrl + "getExamFilters",
      this.httpOptions
    );
  }

  getExamsList(data) {
    return this.http.post<any>(
      this.baseUrl + "getExamsList",
      data,
      this.httpOptions
    );
  }

  getCollegeListForExam(data) {
    return this.http.post<any>(
      this.baseUrl + "getCollegeList",
      data,
      this.httpOptions
    );
  }

  getExamDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "getExamsDetails",
      data,
      this.httpOptions
    );
  }

  addExamsToPartnerBucket(data) {
    return this.http.post<any>(
      this.baseUrl + "addExamsToPartnerBucket",
      data,
      this.httpOptions
    );
  }

  getSubscribedExamsList() {
    return this.http.get<any>(
      this.baseUrl + "getSubscribedExamsList",
      this.httpOptions
    );
  }

  // Scholarships
  // refer separate file - scholarships.service.ts

  // Change Password
  changePassword(data) {
    return this.http.post<any>(
      this.baseUrl + "changePassword",
      data,
      this.httpOptions
    );
  }

  // Feedback Message - Not Working
  resetFeedbackMessage() {
    let data = "aaa";
    setTimeout(() => {
      return data;
    }, 5000);
  }

  // Roadmap (India) : api base url is different

  // Roadmap tab flag Observables
  setRoadmapTabFlag(data) {
    this.roadmapTabSubject.next(data);
  }

  getRoadmapTabFlag(): Observable<any> {
    return this.roadmapTabSubject.asObservable();
  }

  // Preferences (Roadmap)
  setCountryPreference(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "setCountry",
      data,
      this.httpOptions
    );
  }

  getCareerDomain(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getCareerDomain",
      data,
      this.httpOptions
    );
  }

  savePreferences(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "setPrefrences",
      data,
      this.httpOptions
    );
  }

  // College-Map (College-map)
  getRoadmapCollegeMapFilters(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getFilterData",
      data,
      this.httpOptions
    );
  }

  getCollegesList(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getCollegesList",
      data,
      this.httpOptions
    );
  }

  getShortlistedCollegesList(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "viewShortlistedColleges",
      data,
      this.httpOptions
    );
  }

  saveColleges(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "saveColleges",
      data,
      this.httpOptions
    );
  }

  saveAbroadColleges(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "saveColleges",
      data,
      this.httpOptions
    );
  }

  save;
  // Exam Shortlist
  getRoadmapExamsList(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getExamsList",
      data,
      this.httpOptions
    );
  }

  getCollegeListForExamRoadmap(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getCollegeListForExams",
      data,
      this.httpOptions
    );
  }

  saveExamsRoadmap(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "saveExams",
      data,
      this.httpOptions
    );
  }

  saveRoadmapName(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "assignRoadmapName",
      data,
      this.httpOptions
    );
  }

  getSubscribedExamsListRoadmap(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "viewShortlistedExams",
      data,
      this.httpOptions
    );
  }

  // Saved Roadmaps and Operations
  getSavedRoadmapList(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getRoadmapList",
      data,
      this.httpOptions
    );
  }

  deleteRoadmap(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "removeRoadmap",
      data,
      this.httpOptions
    );
  }

  copyRoadmap(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "copyRoadmap",
      data,
      this.httpOptions
    );
  }

  getStudentNamesList(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getStudentNames",
      data,
      this.httpOptions
    );
  }

  assignStudentWithRoadmap(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "mapStudentWithRoadmap",
      data,
      this.httpOptions
    );
  }

  getListOfAssignedStudents(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "getListOfStudentForRoadmap",
      data,
      this.httpOptions
    );
  }

  unAssignStudentFromRoadmap(data) {
    return this.http.post<any>(
      this.roadmapBaseUrl + "removeStudentsRoadmap",
      data,
      this.httpOptions
    );
  }

  getCurrentRoadmapPreferences() {
    return this.http.get<any>(
      this.roadmapBaseUrl + "getPrefrences",
      this.httpOptions
    );
  }

  // Roadmap (Abroad) : api base url is different

  // Preferences (Abroad Roadmap)
  setAbroadCountryPreference(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "setCountry",
      data,
      this.httpOptions
    );
  }

  getAbroadCareerDomain(data) {
    // console.log("Service call");
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "getCareerDomain",
      data,
      this.httpOptions
    );
  }

  // Saving Abroad Roadmap
  getSavedAbroadRoadmapList(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "getRoadmapList",
      data,
      this.httpOptions
    );
  }

  saveAbroadRoadmapName(data) {
    console.log(this.abroadRoadmapBaseUrl);
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "assignRoadmapName",
      data,
      this.httpOptions
    );
  }
  assignStudentWithAbroadRoadmap(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "mapStudentWithRoadmap",
      data,
      this.httpOptions
    );
  }

  getAbroadShortlistedCollegesList(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "viewShortlistedColleges",
      data,
      this.httpOptions
    );
  }
  copyAbroadRoadmap(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "copyRoadmap",
      data,
      this.httpOptions
    );
  }

  getStudentAbroadRoadmapId(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "getStudentRoadmapId",
      data,
      this.httpOptions
    );
  }

  //List Of Students who are assigned to Abroad Roadmap
  getListOfAssignedStudentsAbroad(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "getListOfStudentForRoadmap",
      data,
      this.httpOptions
    );
  }

  deleteAbroadRoadmap(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "removeRoadmap",
      data,
      this.httpOptions
    );
  }

  unAssignStudentFromAbroadRoadmap(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "removeStudentsRoadmap",
      data,
      this.httpOptions
    );
  }

  saveAbroadPreferences(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "setPrefrences",
      data,
      this.httpOptions
    );
  }

  getAbroadCurrentRoadmapPreferences() {
    return this.http.get<any>(
      this.abroadRoadmapBaseUrl + "getPrefrences",
      this.httpOptions
    );
  }

  // College-Map (Abroad Roadmap)
  getAbroadRoadmapCollegeMapFilters() {
    return this.http.get<any>(
      this.abroadRoadmapBaseUrl + "getFilterData",
      this.httpOptions
    );
  }

  getAbroadCollegesList(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "getCollegesList",
      data,
      this.httpOptions
    );
  }

  getAbroadCollegeDetails(data) {
    return this.http.post<any>(
      this.abroadRoadmapBaseUrl + "getCollegeDetails",
      data,
      this.httpOptions
    );
  }

  // Dashboard
  getDashboardData() {
    return this.http.get<any>(this.baseUrl + "dashboard", this.httpOptions);
  }

  searchCareers(data) {
    return this.http.post<any>(
      this.baseUrl + "searchCareers",
      data,
      this.httpOptions
    );
  }

  getBlogCategories() {
    return this.http.get<any>(
      this.baseUrl + "blogCategories",
      this.httpOptions
    );
  }

  getLatestBlogs() {
    return this.http.get<any>(this.baseUrl + "latestBlog", this.httpOptions);
  }

  searchBlog(data) {
    return this.http.post<any>(
      this.baseUrl + "searchBlog",
      data,
      this.httpOptions
    );
  }

  // My Profile

  // Profile Image flag Observable
  setProfileImageFlag(data) {
    this.profileImageSubject.next(data);
  }

  getProfileImageFlag(): Observable<any> {
    return this.profileImageSubject.asObservable();
  }

  setCareerLibraryFlag(data) {
    this.careerLibrarySubject.next(data);
  }

  getCareerLibraryFlag(): Observable<any> {
    return this.careerLibrarySubject.asObservable();
  }

  getProfileInfo() {
    return this.http.get<any>(this.baseUrl + "getMyProfile", this.httpOptions);
  }

  updateProfileInfo(data) {
    return this.http.post<any>(
      this.baseUrl + "updateMyProfile",
      data,
      this.httpOptions
    );
  }

  uploadProfileImage(data) {
    return this.http.post<any>(
      this.baseUrl + "uploadProfileImage",
      data,
      this.httpOptionsImg
    );
  }

  // Help
  submitMessage(data) {
    return this.http.post<any>(this.baseUrl + "help", data, this.httpOptions);
  }

  /*partner resources*/
  getResources(data) {
    return this.http.post<any>(
      this.baseUrl + "getResources",
      data,
      this.httpOptions
    );
  }

  getStudentsCSV() {
    return this.http.get<any>(
      this.baseUrl + "downloadStudentCSV",
      this.httpOptions
    );
  }

  managePlanBtnDisabled(flag) {
    return this.planBtnDisabled.next(flag);
  }

  getStudentLoginDetails(data) {
    return this.http.post<any>(
      this.baseUrl + "getLoginDetails", data, this.httpOptions
    );
  }
  get planBtnDisabledStatus() {
    return this.$planBtnDisabled;
  }
  getCounselorData(data) {
    return this.http.post<any>(
      this.baseUrl + "getMyProfile", data, this.httpOptions
    );
  }
  getNewTeamMember(data) {
    return this.http.post<any>(
      this.baseUrl + "addNewTeamMember",
      data
    );
  }
  getPartnerServicesAndFeatures() {
    return this.http.get<any>(
      this.baseUrl + "partnerServicesAndFeatures", this.httpOptions
    );
  }

  addAssessmentCoupons(data) {
    return this.http.post<any>(
      this.baseUrl + "generateCoupons",
      data
    );
  }

  updateStudentServices(data) {
    return this.http.post<any>(
      this.baseUrl + "updateStudentServices",
      data
    );
  }

  getvipPurchasePlan() {
    return this.http.get<any>(
      this.baseUrl + "vipPurchasePlan", this.httpOptions
    );
  }

  getPriceForVip(data) {
    return this.http.post<any>(
      this.baseUrl + "vipAmount",
      data,
      this.httpOptions
    );
  }

  getStudentVip(data) {
    return this.http.post<any>(
      this.baseUrl + "studentVipData",
      data,
      this.httpOptions
    );
  }

  getPartnerFeatures() {
    return this.http.get<any>(
      this.baseUrl + "partnerFeatures", this.httpOptions
    );
  }

  postWhitelabellingPartnerFeatures(data) {
    return this.http.post<any>(
      this.baseUrl + "updatePartnerFeatures",
      data,
      this.httpOptions
    );
  }
}
