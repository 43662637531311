import { Component, OnInit, NgZone } from '@angular/core';
import { PreloginService } from '../../services/prelogin.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ValidationsService } from '../../services/common/validations.service';
import { DashboardService } from '../../services/dashboard.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.scss']
})
export class MyDashboardComponent implements OnInit {

  isLoading = true;
  isCareerLoading = true;
  isBlogLoading = true;

  loggedInUserDetails;
  userRole;
  userRoleName;

  dashboardApiResponse;
  websiteProgressResponse;

  careerList = [];

  statsData = [];

  websiteProgressData = [];
  myWebsiteActionLabel = 'Manage';

  userWebsiteAddress;

  // Stats and Buy More
  couponDetailsAndPriceResponse;
  isBuyMoreClicked = false;
  buyMoreSuccessEventMessage;

  blogSearchForm: FormGroup;
  isBlogSearchFormSubmitted: boolean;

  careerSearchForm: FormGroup;
  isCareerSearchFormSubmitted: boolean;

  // Blog
  blogCategoriesFromApi;
  latestBlogsFromApi;

  // Profile Photo
  isLargeImage = false;
  isImageUploaded = true;
  uploadedImageName = '';
  displayImageUrl = '';
  profilePhotoPostData: any = {};
  profilePhotoUploadUserId;

  // Add Team Member
  myTeamActionLabel = 'Add members';
  teamMemberForm: FormGroup;
  submitted = false;
  emailsList: string[] = [];
  addTeamSuccessMessage;
  addTeamErrorMessage;

  careerLibraryUrl = 'https://www.mindler.com/careerlibrary/';
  partnerWebsiteUrl = '';
  websiteCompletionPercentage = 0;

  constructor(private zone: NgZone, private formBuilder: FormBuilder, private validationService: ValidationsService, private dashboardService: DashboardService, private preloginService: PreloginService, private router: Router) {
    // subscribe to Coupons Bought Success flag
    this.dashboardService.getCouponBoughtFlag().subscribe(data => {
      this.zone.run(() => {
        this.isBuyMoreClicked = false;
        this.getDashboardData();
        window.scrollTo(0, 0);
      });
    });

  }

  ngOnInit() {

    this.loggedInUserDetails = this.dashboardService.decodeToken();

    console.log('dashboard', this.loggedInUserDetails);

    // 0 - Partner, 1 - Admin, 2 - Counsellor
    this.userRole = this.preloginService.getCurrentRole();
    if (this.userRole === 0) {
      this.userRoleName = 'Partner';
      this.profilePhotoUploadUserId = this.loggedInUserDetails.partner_id;
    } else if (this.userRole === 1) {
      this.userRoleName = 'Admin';
      this.profilePhotoUploadUserId = this.loggedInUserDetails.counselor_id;
    } else if (this.userRole === 2) {
      this.userRoleName = 'Counsellor';
      this.profilePhotoUploadUserId = this.loggedInUserDetails.counselor_id;
    }

    this.statsData = [];
    this.websiteProgressData = [];

    this.getDashboardData();

    this.getBlogCategories();
    this.getLatestBlogs();

    this.blogSearchForm = this.formBuilder.group({
      blogSearch: ['', Validators.required]
    });

    this.careerSearchForm = this.formBuilder.group({
      careerSearch: ['', Validators.required]
    });

    // Add Team Member
    this.teamMemberForm = this.formBuilder.group({
      teamMembers: new FormArray([])
    });
    this.addMemberField();
  }

  // getters
  // Blog
  get blogSearch() { return this.blogSearchForm.get('blogSearch'); }

  // Career
  get careerSearch() { return this.careerSearchForm.get('careerSearch'); }

  // getter for Add Team Member Form Array (teamMembers)
  get teamMemberFormField() { return this.teamMemberForm.get('teamMembers') as FormArray; }


  getDashboardData() {
    this.isLoading = true;
    this.isCareerLoading = true;

    this.dashboardService.getDashboardData().subscribe(response => {
      this.isLoading = false;
      this.isCareerLoading = false;

      this.dashboardService.setCareerLibraryFlag({career_library_enabled : response.data.is_careerlibrary_menu_enabled,url : response.data.website,white_labelling : response.data.white_labelling});

      if(response.data.is_careerlibrary_menu_enabled===1){
        this.careerLibraryUrl = response.data.website+"/careerlibrary/";
      }

      if (response.status === true && response.statusCode === 200) {
        this.dashboardApiResponse = response.data;
        this.websiteProgressResponse = response.data.profileCompletionStatus;

        if (this.userRole === 0 || this.userRole === 1) {
          if ((response.data.website).indexOf('https://') === -1) {
            this.userWebsiteAddress = 'https://' + response.data.website;
          }
          else {
            this.userWebsiteAddress = response.data.website;
          }
          this.getWebsiteProgressData();
        }

        this.careerList = response.data.careerList;

        this.getStats();
      }
    });
  }


  getStats() {
    this.statsData = [];

    if (this.dashboardApiResponse.hasOwnProperty('couponRemainingCount')) {
      this.statsData.push({ label: 'Assessment Coupons Remaining', count: this.dashboardApiResponse.couponRemainingCount, redirectLink: '/assessment-coupons' });
    }
    if (this.dashboardApiResponse.hasOwnProperty('vipRemainingCount')) {
      this.statsData.push({ label: 'VIP Coupons Remaining', count: this.dashboardApiResponse.vipRemainingCount, redirectLink: '/assessment-coupons' });
    }
    if (this.dashboardApiResponse.hasOwnProperty('countTeam')) {
      this.statsData.push({ label: 'Team Members', count: this.dashboardApiResponse.countTeam, redirectLink: '/my-team' });
    }
    if (this.dashboardApiResponse.hasOwnProperty('countStudentAssigned')) {
      this.statsData.push({ label: 'Total Students', count: this.dashboardApiResponse.countStudentAssigned, redirectLink: '/student-list' });
    }
    if (this.dashboardApiResponse.hasOwnProperty('roadmapCompleted')) {
      this.statsData.push({ label: 'Roadmaps Completed', count: this.dashboardApiResponse.roadmapCompleted, redirectLink: '/roadmap' });
    }
  }


  getWebsiteProgressData() {
    if (Object.keys(this.dashboardApiResponse.profileCompletionStatus).length === 0) {
      // object is empty
    }
    else {
      this.websiteProgressData = [
        { label: 'Organization Details', percentage: this.websiteProgressResponse.countOrgnizationCompletion, link: '/organization-details' },
        { label: 'Branding', percentage: this.websiteProgressResponse.countBrandingCompletion, link: '/branding' },
        { label: 'Information', percentage: this.websiteProgressResponse.countInformationCompletion, link: '/information' },
        { label: 'Testimonials', percentage: this.websiteProgressResponse.countTestimonialCompletion, link: '/testimonials' }
      ];

      //Calculation Average of Individual Progresses
      this.websiteCompletionPercentage = 0;
      for(var i=0;i<this.websiteProgressData.length;i++){
        this.websiteCompletionPercentage+= parseInt(this.websiteProgressData[i].percentage);
      }
      this.websiteCompletionPercentage=Math.floor(this.websiteCompletionPercentage/this.websiteProgressData.length);
    }
  }

  updateMyWebsiteActionLabel() {
    if (this.myWebsiteActionLabel === 'Manage') {
      this.myWebsiteActionLabel = 'Close';
    }
    else {
      this.myWebsiteActionLabel = 'Manage';
    }
  }


  // Blog
  // Categories
  getBlogCategories() {
    this.dashboardService.getBlogCategories().subscribe(response => {
      this.blogCategoriesFromApi = response.data;
    });
  }


  // Latest Blogs
  getLatestBlogs() {
    this.isBlogLoading = true;

    this.dashboardService.getLatestBlogs().subscribe(response => {
      this.isBlogLoading = false;
      this.latestBlogsFromApi = response.data;
    });
  }

  // Blog
  getModifiedImage(blog) {
    let path;
      console.log(blog);
    if (blog.hasOwnProperty('_embedded') && blog._embedded['wp:featuredmedia'] && blog._embedded['wp:featuredmedia'][0].code!='rest_forbidden' && blog._embedded['wp:featuredmedia'].length > 0) {
      var imagePath = blog._embedded['wp:featuredmedia'][0].source_url;

      var pathArray = imagePath.split('/');
      path = 'https://mindlerblog.imgix.net/' + pathArray[7] + '/' + pathArray[8] + '/' + pathArray[9] + '/' + pathArray[10] + '?w=250&h=200&auto=compress';
    }
    else {
      path = '';
    }
    return imagePath;
  }


  blogSearchFormSubmit() {
    this.isBlogSearchFormSubmitted = true;

    if (this.blogSearchForm.invalid) {
      return;
    }

    this.isBlogLoading = true;
    this.dashboardService.searchBlog({ search: this.blogSearch.value }).subscribe(response => {
      this.isBlogLoading = false;

      this.latestBlogsFromApi = response.data;
      if (response.status === true && response.statusCode === 200) {
      }
    });
  }


  resetLatestBlogs() {
    if (this.blogSearch.value === '') {
      this.isBlogSearchFormSubmitted = false;

      this.getLatestBlogs();
    }
  }


  // Buy More
  buyMoreClickAction() {
    this.isBuyMoreClicked = !this.isBuyMoreClicked;
  }


  careerSearchFormSubmit() {
    this.isCareerSearchFormSubmitted = true;

    if (this.careerSearchForm.invalid) {
      return;
    }
    this.isCareerLoading = true;

    this.dashboardService.searchCareers({ search: this.careerSearch.value }).subscribe(response => {
      this.isCareerLoading = false;

      if (response.status === true && response.statusCode === 200) {
        this.careerList = response.data.careerList;
      }
    });
  }

  getAllCareerCategories() {
    if (this.careerSearch.value === '') {
      this.isCareerSearchFormSubmitted = false;

      this.dashboardService.searchCareers({ search: this.careerSearch.value }).subscribe(response => {
        if (response.status === true && response.statusCode === 200) {
          this.careerList = response.data.careerList;
        }
      });
    }
  }


  // Image
  uploadImage(event) {
    if (event.target.files[0].size > 500000) {
      this.isLargeImage = true;
    }
    else {
      this.isLargeImage = false;

      const fileName = event.target.files[0].name;
      const newFileName = fileName.replace(/ /g, '-');

      const uploadData = new FormData();
      uploadData.append('image', event.target.files[0], newFileName);

      this.dashboardService.uploadProfileImage(uploadData).subscribe(data => {
        this.uploadedImageName = data.name;

        if (this.userRole === 0) {
          this.displayImageUrl = environment.partner_photo_baseUrl + this.profilePhotoUploadUserId + '/' + this.uploadedImageName;
        }
        else if (this.userRole === 1 || this.userRole === 2) {
          this.displayImageUrl = environment.counsellor_photo_baseUrl + this.profilePhotoUploadUserId + '/' + this.uploadedImageName;
        }

        this.dashboardService.getProfileInfo().subscribe(response => {
          if (response.statusCode === 200 && response.status === true) {
            this.profilePhotoPostData.name = response.data.userInfo[0].name;

            if ((response.data.userInfo[0]).hasOwnProperty('phone')) {
              this.profilePhotoPostData.phone = response.data.userInfo[0].phone;
            }
            else if ((response.data.userInfo[0]).hasOwnProperty('mobile')) {
              this.profilePhotoPostData.phone = response.data.userInfo[0].mobile;
            }
          }
          this.profilePhotoPostData.profile_image = this.uploadedImageName;

          this.dashboardService.updateProfileInfo(this.profilePhotoPostData).subscribe(response => {
            // update token
            localStorage.setItem('pp_token', response.data.token);
            this.dashboardService.setProfileImageFlag({ imageUrl: this.displayImageUrl, name: this.profilePhotoPostData.name });
            this.dashboardApiResponse.profileImageAllowed = 0;
          });
        });
      });
    }
  }


  // Add Team Member
  updateMyTeamActionLabel() {
    if (this.myTeamActionLabel === 'Close') {
      this.myTeamActionLabel = 'Add Members';
    }
    else {
      this.myTeamActionLabel = 'Close';
    }
  }

  addMemberField() {
    this.teamMemberFormField.push(
      this.formBuilder.group({
        emailId: ['', [Validators.required, this.validationService.validateEmail]]
      })
    );
    this.submitted = false;
  }

  removeMemberField(formIndex) {
    this.teamMemberFormField.removeAt(formIndex);
  }

  addTeamMembersFormSubmit() {
    this.emailsList = [];

    if (this.teamMemberFormField.length == 0) {
      return;
    }

    this.submitted = true;

    if (this.teamMemberForm.invalid) {
      return;
    }

    (this.teamMemberForm.get('teamMembers').value).forEach(element => {
      this.emailsList.push(element.emailId);
    });

    const postData = {
      emails: this.emailsList
    };


    this.dashboardService.sendInvitations(postData).subscribe(response => {
      if (response.status === true && response.statusCode === 200) {
        this.addTeamSuccessMessage = 'Invite sent!';
        this.addTeamErrorMessage = null;
        this.teamMemberForm.reset();
        this.submitted = false;
        this.emailsList = [];
        while (this.teamMemberFormField.length != 0) {
          this.teamMemberFormField.removeAt(0);
        }
        this.addMemberField();

        setTimeout(() => {
          this.addTeamSuccessMessage = null;
          $('#addTeamForm').collapse('hide');
          this.myTeamActionLabel = 'Add Members';
        }, 3000);
      }
      else if (response.status === false && response.statusCode === 200) {
        this.addTeamErrorMessage = response.message;
        this.addTeamSuccessMessage = null;
      }
    });
  }


  navigateToPage(redirectLink) {
    this.router.navigate([redirectLink]);
  }

}
