import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Prelogin
import { PartnerRegistrationComponent } from './prelogin/partner-registration/partner-registration.component';
import { CounsellorSignupPreprocessingComponent } from './prelogin/counsellor-signup-preprocessing/counsellor-signup-preprocessing.component';
import { CounsellorRegistrationComponent } from './prelogin/counsellor-registration/counsellor-registration.component';
import { LoginComponent } from './prelogin/login/login.component';
import { ResetPasswordComponent } from './prelogin/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './prelogin/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './prelogin/verify-email/verify-email.component';

// Menu - Dashboard
import { MyDashboardComponent } from './dashboard/my-dashboard/my-dashboard.component';

// Menu - My Website
import { BrandingComponent } from './dashboard/branding/branding.component';
import { InformationComponent } from './dashboard/information/information.component';
import { TestimonialsComponent } from './dashboard/testimonials/testimonials.component';

// Menu - My Organization
import { OrganizationDetailsComponent } from './dashboard/organization-details/organization-details.component';
import { MyTeamComponent } from './dashboard/my-team/my-team.component';
import { BillingInvoiceComponent } from './dashboard/billing-invoice/billing-invoice.component';
import { AssessmentCouponsComponent } from './dashboard/assessment-coupons/assessment-coupons.component';
import { WhitelabellingOptionsComponent } from './dashboard/whitelabelling-options/whitelabelling-options.component';

// Upgrade Page
import { UpgradeComponent } from './dashboard/upgrade/upgrade.component';

// Menu - Student
import { StudentListComponent } from './dashboard/student-list/student-list.component';

// Student Profile
import { StudentProfileComponent } from './dashboard/student-profile/student-profile.component';
import { InfoComponent } from './dashboard/student-profile-partials/info/info.component';
import { TestsComponent } from './dashboard/student-profile-partials/tests/tests.component';
import { MatchesComponent } from './dashboard/student-profile-partials/matches/matches.component';
import { CareerProfileComponent } from './dashboard/student-profile-partials/career-profile/career-profile.component';
import { StudentRoadmapComponent } from './dashboard/student-profile-partials/student-roadmap/student-roadmap.component';
import { ActionPlanComponent } from './dashboard/student-profile-partials/action-plan/action-plan.component';
import { InsightsComponent } from './dashboard/student-profile-partials/insights/insights.component';

import { NotesComponent } from './dashboard/student-profile-partials/notes/notes.component';

// Menu - My Planning Tools
import { ExamCornerComponent } from './dashboard/exam-corner/exam-corner.component';
import { ExamCornerDetailsComponent } from './dashboard/exam-corner-details/exam-corner-details.component';
import { ScholarshipsComponent } from './dashboard/scholarships/scholarships.component';
import { RoadmapComponent } from './dashboard/roadmap/roadmap.component';

import { PreferencesComponent } from './dashboard/roadmap-partials/preferences/preferences.component';
import { CollegeMapComponent } from './dashboard/roadmap-partials/college-map/college-map.component';
import { ExamShortlistComponent } from './dashboard/roadmap-partials/exam-shortlist/exam-shortlist.component';
import { SavedRoadmapsComponent } from './dashboard/roadmap-partials/saved-roadmaps/saved-roadmaps.component';
import { AbroadPreferencesComponent } from './dashboard/roadmap-partials/abroad-preferences/abroad-preferences.component';
import { AbroadCollegesComponent } from './dashboard/roadmap-partials/abroad-colleges/abroad-colleges.component';

// Change Password
import { ChangePasswordComponent } from './dashboard/change-password/change-password.component';

// Profile
import { ProfileComponent } from './dashboard/profile/profile.component';

// Lock Screen
import { LockScreenComponent } from './dashboard/lock-screen/lock-screen.component';

// Help
import { HelpComponent } from './dashboard/help/help.component';

// Performance Report
import { PerformanceReportComponent } from './dashboard/performance-report/performance-report.component';

import { AuthGuard } from './auth.guard';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';

import { DashboardGridComponent } from './dashboard/dashboard-grid/dashboard-grid.component';

// Super-admin
import { AdminLoginComponent } from './prelogin/admin-login/admin-login.component';
import { PartnersOverviewComponent } from './superadmin/partners-overview/partners-overview.component';
import { PartnerDetailsComponent } from './superadmin/partner-details/partner-details.component';
import { AdminMarketingResourcesComponent } from './superadmin/marketing-resources/marketing-resources.component';
import { ResourcesComponent } from './dashboard/resources/resources.component';
import { LoginWithMindlerTokenComponent } from './dashboard/login-with-mindler-token/login-with-mindler-token.component';
import { VipComponent } from './dashboard/student-profile-partials/vip/vip.component';
import { DocumentArchivesComponent } from './dashboard/student-profile-partials/document-archives/document-archives.component';
import { NexusComponent } from './dashboard/student-profile-partials/nexus/nexus.component';


// Roles
// 0 - Partner
// 1 - Admin
// 2 - Counsellor
// 3 - Super-admin

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'partner-registration',
    component: PartnerRegistrationComponent,
    data: { title: 'Signup as a Partner with Mindler' }
  },
  {
    path: 'counselorSignup',
    component: CounsellorSignupPreprocessingComponent,
    data: { title: 'Signup as a Partner Counsellor with Mindler' }
  },
  {
    path: 'counsellor-registration',
    component: CounsellorRegistrationComponent,
    data: { title: 'Signup as a Partner Counsellor with Mindler' }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Partner Login - Mindler' }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { title: 'New Password for Partner Platform' }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Reset Your Password for Partner Platform' }
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    data: { title: 'Email Verification for Partner Platform' }
  },
  {
    path: 'my-dashboard',
    component: MyDashboardComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Dashboard - Partner' }
  },
  {
    path: 'branding',
    component: BrandingComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1], title: 'Website Branding - Partner' }
  },
  {
    path: 'information',
    component: InformationComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1], title: 'Website Information - Partner' }
  },
  {
    path: 'testimonials',
    component: TestimonialsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1], title: 'Website Testimonials - Partner' }
  },
  {
    path: 'settings',
    component: WhitelabellingOptionsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0], title: 'Settings - Partner' }
  },
  {
    path: 'organization-details',
    component: OrganizationDetailsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1], title: 'Organization Details - Partner' }
  },
  {
    path: 'my-team',
    component: MyTeamComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1], title: 'My Team - Partner' }
  },
  {
    path: 'billing',
    component: BillingInvoiceComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0], title: 'Billing Information - Partner' }
  },
  {
    path: 'assessment-coupons',
    component: AssessmentCouponsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0], title: 'List of Assessment Coupons - Partner' }
  },
  {
    path: 'upgrade',
    component: UpgradeComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0], title: 'Upgrade Page - Partner' }
  },
  {
    path: 'student-list',
    component: StudentListComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'List of Students - Partner' }
  },

  {
    path: 'student-profile/:student_id',
    component: StudentProfileComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2] },
    children: [
      { path: '', redirectTo: 'info', pathMatch: 'full' },
      { path: 'info', component: InfoComponent, data: { title: 'Student Profile - Information - Partner' } },
      { path: 'tests', component: TestsComponent, data: { title: 'Student Profile - Tests - Partner' } },
      { path: 'vip', component: VipComponent, data: { title: 'Student Profile - vip - Partner' } },
      { path: 'matches', component: MatchesComponent, data: { title: 'Student Profile - Matches - Partner' } },
      { path: 'career-profile', component: CareerProfileComponent, data: { title: 'Student Profile - Activities - Partner' } },
      { path: 'notes', component: NotesComponent, data: { title: 'Student Profile - Notes - Partner' } },
      { path: 'document-archives', component: DocumentArchivesComponent, data: { title: 'Student Profile - Document Archives - Partner' } },
      { path: 'action-plan', component: ActionPlanComponent, data: { title: 'Student Profile - Action Plan - Partner' } },
      { path: 'insights', component: InsightsComponent, data: { title: 'Student Profile - Activities Insights - Partner' } },
      { path: 'nexus', component: NexusComponent, data: { title: 'Student Profile - Nexus - Partner' }},
      {
        path: 'student-roadmap',
        component: StudentRoadmapComponent,
        children: [
          // { path: '', redirectTo: 'preferences', pathMatch: 'full' },
          { path: 'preferences', component: PreferencesComponent, data: { title: 'Student Profile - Roadmap - Preferences - Partner' } },
          { path: 'abroad/preferences', component: AbroadPreferencesComponent, data: { title: 'Student Profile - Abroad -Roadmap - Preferences - Partner' } },
          { path: 'college-map', component: CollegeMapComponent, data: { title: 'Student Profile - Roadmap - College Map - Partner' } },
          { path: 'abroad/college-map', component: AbroadCollegesComponent, data: { title: 'Roadmap - Abroad - College Map - Partner' } },
          { path: 'exam-shortlist', component: ExamShortlistComponent, data: { title: 'Student Profile - Roadmap - Exam Shortlist - Partner' } }
        ]
      }
    ]
  },
  {
    path: 'exam-corner',
    component: ExamCornerComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Exam Corner - Partner' }
  },
  {
    path: 'exam-corner/:mep_id/:id',
    component: ExamCornerDetailsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Exam Corner- Exam Details - Partner' }
  },
  {
    path: 'scholarships',
    component: ScholarshipsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Scholarships - Partner' }
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Resources - Partner' }
  },
  {
    path: 'roadmap',
    component: RoadmapComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Roadmap - Partner' },
    children: [
      { path: 'preferences', component: PreferencesComponent, data: { title: 'Roadmap - Preferences - Partner' } },
      { path: 'college-map', component: CollegeMapComponent, data: { title: 'Roadmap - College Map - Partner' } },
      { path: 'exam-shortlist', component: ExamShortlistComponent, data: { title: 'Roadmap - Exam Shortlist - Partner' } },
      { path: 'abroad/preferences', component: AbroadPreferencesComponent, data: { title: 'Roadmap - Abroad - Preferences - Partner' } },
      { path: 'abroad/college-map', component: AbroadCollegesComponent, data: { title: 'Roadmap - Abroad - College Map - Partner' } }
    ]
  },
  {
    path: 'saved-roadmaps/:location',
    component: SavedRoadmapsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Saved Roadmaps - Partner' }
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Change Password - Partner' }
  },
  {
    path: 'my-profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'My Profile - Partner' }
  },
  {
    path: 'grid',
    component: DashboardGridComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2] }
  },
  {
    path: 'plan-expired',
    component: LockScreenComponent,
    data: { title: 'Plan Expired - Partner' }
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0, 1, 2], title: 'Help - Partner' }
  },
  {
    path: 'performance-report',
    component: PerformanceReportComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [0], title: 'Performance Reports - Partner' }
  },
  {
    path: 'admin',
    component: AdminLoginComponent,
    data: { title: 'Admin Login - Partner' }
  },
  {
    path: 'partners-overview',
    component: PartnersOverviewComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [3], title: 'Partners Overview - Partner' },
  },
  {
    path: 'partners-overview/:partner_id',
    component: PartnerDetailsComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [3], title: 'Partners Overview - Partner Details - Partner' }
  },
  {
    path: 'marketing',
    component: AdminMarketingResourcesComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: [3], title: 'Marketing Resources - Partner' },
  },
  {
    path: 'loginWithMindlerToken/:token',
    component: LoginWithMindlerTokenComponent,
    data: { expectedRole: [0, 2], title: "Login With Mindler Token Redirect" }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: '404 Error - Partner' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
