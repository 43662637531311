import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreloginService } from '../../services/prelogin.service';
import { DashboardService } from '../../services/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  loginBtnLabel = 'Log In';

  loginForm: FormGroup;
  isLoginFormSubmitted = false;

  isInvalidPassword = false;
  userNotExists = false;
  isErroredOut = false;
  isSubscriptionExpired = false;
  fieldTextType: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private preLoginService: PreloginService, private dashboardService: DashboardService) { }

  ngOnInit() {
    // redirect already logged in user to dashboard
    if (localStorage.getItem('pp_session') != null) {
      this.router.navigate(['/partners-overview']);
    }

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // getters
  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  // Login Form submit
  loginFormSubmit() {
    this.resetFlags();

    this.isLoginFormSubmitted = true;

    if (this.loginForm.invalid) {
      return;

    }
    this.loginBtnLabel = 'Logging In...';

    this.preLoginService.adminLogin(this.loginForm.value).subscribe(data => {
      this.loginBtnLabel = 'Log In';
      if (data.statusCode === 200 && data.status === false) {
        this.isInvalidPassword = data.message;
      }
      else if (data.statusCode === 404 && data.status === false) {
        this.userNotExists = data.message;
      }
      else if (data.statusCode === 500 && data.status === false) {
        this.isErroredOut = data.message;
      }
      else if (data.statusCode === 200 && data.status === true) {
        this.router.navigate(['/partners-overview']);
      }
    },
      error => {
        this.loginBtnLabel = 'Log In';
      });
  }

  resetFlags() {
    this.isInvalidPassword = false;
    this.userNotExists = false;
    this.isSubscriptionExpired = false;
  }

  
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  selectedBorder() {
    var eyeIcon = document.getElementById('eye-icon');
    eyeIcon.style.borderBottom = "2px solid #007fb6";
  }

  unselectedBorder() {
    var eyeIcon = document.getElementById('eye-icon');
    eyeIcon.style.borderBottom = "2px #e9e9e9 solid";
  }
}
